import React, { useEffect, useState, useRef } from 'react';
import ReactGA from 'react-ga4';
import { json, useNavigate } from 'react-router-dom';
import { HttpHandler } from 'shared/httpHandler';
import { CryptoHandler } from '../../shared/cryptoHandler';
import { Header } from 'antd/es/layout/layout';
import { type } from '@testing-library/user-event/dist/type';
import { all } from 'axios';
import dayjs from 'dayjs';

const Home = () => {
  const httpHandler = new HttpHandler();
  const cryptoHandler = new CryptoHandler();
  const navigate = useNavigate();
  // const [all_data, setAll] = useState({});
  const all_data = useRef({});
  const [loading, setLoading] = useState(true);
  const access_token = useRef('');
  const searchParams = new URLSearchParams(window.location.search);
  const memrfnbr = searchParams.get('MEMRFNBR');
  localStorage.setItem('Inviter', memrfnbr || '');

  if (memrfnbr === null) {
    const queryString = window.location.search.substring(1); // 去掉 '?'
    const params = new URLSearchParams(queryString);
    let value = params.get('Token');
    if (value !== null) {
      value = value.replace(/ /g, '+');
      localStorage.setItem('Method', value);
    }
  }

  useEffect(() => {
    // 追蹤頁面瀏覽
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  useEffect(() => {
    const time = localStorage.getItem('ExpireTime');

    // 判斷token過期了沒，預設時間1天
    if (time !== null) {
      const new_time = cryptoHandler.decrypt(time, 2);
      const expireTime = dayjs(new_time, 'YYYY-MM-DD HH:mm:ss', true);
      const now = dayjs();
      if (!expireTime.isValid() || now.isAfter(expireTime)) {
        // localStorage.clear(); // 清空 localStorage
        localStorage.removeItem('AccessToken');
        localStorage.removeItem('AllMemberData');
        localStorage.removeItem('EventToken');
        localStorage.removeItem('ExpireTime');
        localStorage.removeItem('Inviter');
        localStorage.removeItem('MEMRFNBR');
        localStorage.removeItem('UserData');
        localStorage.removeItem('RankData');
      }
    } else {
      // localStorage.clear();
      localStorage.removeItem('AccessToken');
      localStorage.removeItem('AllMemberData');
      localStorage.removeItem('EventToken');
      localStorage.removeItem('ExpireTime');
      localStorage.removeItem('Inviter');
      localStorage.removeItem('MEMRFNBR');
      localStorage.removeItem('UserData');
      localStorage.removeItem('RankData');
    }

    // 加密
    let postData = {
      EventID: 'EventSpace',
      CompanyID: 'SpaceTour',
      CompanyPD: 'HimsSpace2024',
    };

    let encrypt = cryptoHandler.encrypt(JSON.stringify(postData), 1);
    let request = {
      Request: encrypt,
    };
    let request_json = JSON.stringify(request);

    const headers = {
      'Content-Type': 'application/json',
    };

    // 取得AccessToken
    httpHandler
      .post('/api/EventPartner/GetToken', request_json, { headers }, 2)
      .then(async (response) => {
        // 解密
        let decrypt = JSON.parse(cryptoHandler.decrypt(response.Response, 1));
        access_token.current = decrypt.Data.AccessToken;
        const new_acc_tok = cryptoHandler.encrypt(access_token.current, 2);
        localStorage.setItem('AccessToken', new_acc_tok);
        const headers = {
          'Content-Type': 'application/json',
        };

        // 取得資料
        httpHandler
          .get(
            `/api/EventSpace/GetTotalData?NiceCatch=${access_token.current}`,
            { headers }
          )
          .then((response) => {
            all_data.current = JSON.parse(
              cryptoHandler.decrypt(response.Response, 2)
            ).Data;
            // 儲存全體會員資料
            const new_all_data = cryptoHandler.encrypt(
              JSON.stringify(all_data.current),
              2
            );
            localStorage.setItem('AllMemberData', new_all_data);
            setLoading(false);
          });
      });
  }, []);

  // 轉換數字格式 EX: 1000 => 1,000
  const formatNumberString = (numberString) => {
    // 將字串轉換為數字
    const number = parseInt(numberString, 10);

    // 格式化為每三位加逗號的格式
    return number.toLocaleString();
  };

  // 點擊"Go"按鈕
  const handleGoButtonClick = async () => {
    const mem = localStorage.getItem('MEMRFNBR');
    if (mem) {
      await refresh();
    }
    navigate('/logininfo');
  };

  const refresh = async () => {
    const mem = cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2);
    const event = cryptoHandler.decrypt(localStorage.getItem('EventToken'), 2);

    const postData = {
      MEMRFNBR: mem,
      EventToken: event,
      NiceCatch: access_token.current,
    };
    let encrypt = cryptoHandler.encrypt(JSON.stringify(postData), 1);
    let request = {
      Request: encrypt,
    };
    let request_json = JSON.stringify(request);

    const headers = {
      'Content-Type': 'application/json',
    };

    // 取得AccessToken
    await httpHandler.post('/api/EventSpace/RefreshToken', request_json, {
      headers,
    });
  };

  if (loading) {
    return;
  }

  return (
    <div className="index-main">
      {/* <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
        <div className="index-bg" />
      </div> */}
      <div className="logo">
        <img src="/images/logo.png" alt="Logo" />
      </div>
      <div className="container content">
        <div className="word-part">
          <div className="title-pc">
            <img src="/images/Mainword_R.png" alt="Main Word PC" />
          </div>
          <div className="title-mobile">
            <img src="/images/Mainword_mobile_R.png" alt="Main Word Mobile" />
          </div>
          <div className="word-purple">
            2024 年我們突破了&nbsp;
            <span className="word-orange number">
              {formatNumberString(all_data.current.TotalOrderNum)}
            </span>
            &nbsp;趟次訂單數，
            <span className="word-nobreak">
              一起行駛過&nbsp;
              <span className="word-orange number">
                {formatNumberString(all_data.current.TotalRentMile)}
              </span>
              &nbsp;公里的距離
            </span>
            <p className="word-orange">全體會員開的距離足以從地球飛向月球 !</p>
            <p>現在就讓我們一起來回憶</p>
            <p>
              今年這趟<span className="word-blue">iRent銀河冒險之旅</span>吧
            </p>
          </div>
          <div
            className="bottom-mobile"
            id="mobile_btnGo"
            onClick={() => handleGoButtonClick()}
          >
            <img src="/images/index_btnGo.gif" alt="Go Button Mobile" />
          </div>
        </div>
      </div>
      <a id="pc_btnGo" onClick={() => handleGoButtonClick()}>
        <div className="bottom-pc">
          <img src="/images/index_btnGo.gif" alt="Go Button PC" />
        </div>
      </a>
    </div>
  );
};

export default Home;
