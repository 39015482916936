import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { HttpHandler } from 'shared/httpHandler';
import { CryptoHandler } from '../../../shared/cryptoHandler';

const MileStone4 = () => {
  const httpHandler = new HttpHandler();
  const cryptoHandler = new CryptoHandler();
  const navigate = useNavigate();
  const order = JSON.parse(
    cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
  ).OrderNum;
  let percent = JSON.parse(
    cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
  ).OrderPercent;

  if (percent !== 'X' && percent === '0%') {
    percent = '1%';
  }

  useEffect(() => {
    const part_member = document.getElementById('part-member');
    const member = document.getElementById('member');

    if (order === '0' && percent === 'X') {
      member.style.display = 'none';
      part_member.style.display = 'block';
    }

    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const handleLeftButtonClick = () => {
    navigate('/milestone3');
  };
  const handleRightButtonClick = () => {
    PageRecord();
    navigate('/milestone5');
  };

  const formatNumberString = (numberString) => {
    const number = parseInt(numberString, 10); // 將字串轉換為數字
    return number.toLocaleString(); // 格式化為每三位加逗號的格式
  };

  const PageRecord = () => {
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const postData = {
      MEMRFNBR: cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2),
      Page: '5',
      NiceCatch: access_token,
    };
    const encrypt = cryptoHandler.encrypt(JSON.stringify(postData), 2);
    const request = {
      Request: encrypt,
    };
    const request_json = JSON.stringify(request);
    const headers = {
      'Content-Type': 'application/json',
    };
    httpHandler.post(
      '/api/EventSpace/PageRecord',
      request_json,
      { headers },
      2
    );
  };

  return (
    <>
      <div
        className="milestone-main"
        id="part-member"
        style={{ display: 'none' }}
      >
        <div className="milestone4">
          <div className="logo">
            <img src="/images/logo.png" alt="Logo" />
          </div>
          <a id="milestone4ArrowLeft" onClick={() => handleLeftButtonClick()}>
            <div className="arrowLeft"></div>
          </a>
          <a id="milestone4ArrowRight" onClick={() => handleRightButtonClick()}>
            <div className="arrowRight"></div>
          </a>
          <div className="box1">
            <div className="number">
              <img src="/images/number_4.png" alt="" />
            </div>
            <div className="text word-mileorange">
              <p>
                你知道嗎? 這些年來會員數已突破&nbsp;
                <span className="word-white nowrap">
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(
                        localStorage.getItem('AllMemberData'),
                        2
                      )
                    ).TotalMemberCount
                  )}
                </span>
                &nbsp;人，
              </p>
              <p>從1個人到100,000人，iRent 始終保持初心，創新領航、服務躍進</p>
            </div>
          </div>
          <div className="circle-container">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle full"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      </div>
      <div className="milestone-main" id="member">
        <div className="milestone4">
          <div className="logo">
            <img src="/images/logo.png" alt="Logo" />
          </div>
          <a id="milestone4ArrowLeft" onClick={() => handleLeftButtonClick()}>
            <div className="arrowLeft"></div>
          </a>
          <a id="milestone4ArrowRight" onClick={() => handleRightButtonClick()}>
            <div className="arrowRight"></div>
          </a>
          <div className="box1">
            <div className="number">
              <img src="/images/number_4.png" alt="" />
            </div>
            <div className="text">
              <p>
                今年你駕駛iRent體驗了&nbsp;
                <span className="word-mileorange nowrap">
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).OrderNum
                  )}
                </span>
                &nbsp;次太空旅行，排名贏過&nbsp;
                <span className="word-mileorange nowrap">{percent}</span>
                &nbsp;會員，
              </p>
              <p>相信每一次都是難忘的回憶</p>
            </div>
          </div>
          <div className="box2 word-mileorange">
            <p>
              你知道嗎? 這些年來會員數已突破&nbsp;
              <span className="word-white nowrap">
                {formatNumberString(
                  JSON.parse(
                    cryptoHandler.decrypt(
                      localStorage.getItem('AllMemberData'),
                      2
                    )
                  ).TotalMemberCount
                )}
              </span>
              &nbsp;人，
            </p>
            <p>從1個人到100,000人，iRent 始終保持初心，創新領航、服務躍進</p>
          </div>
          <div className="circle-container">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle full"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MileStone4;
