import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { HttpHandler } from 'shared/httpHandler';
import { CryptoHandler } from '../../../shared/cryptoHandler';

const MileStone8 = () => {
  const httpHandler = new HttpHandler();
  const cryptoHandler = new CryptoHandler();
  const navigate = useNavigate();
  useEffect(() => {
    const insurance = JSON.parse(
      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
    ).InsuranceCount;

    const month = JSON.parse(
      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
    ).MonthlyCount;

    const pro = JSON.parse(
      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
    ).ProCount;

    const part_member = document.getElementById('part-member');
    const member = document.getElementById('member');

    if (insurance === '0' && month === '0' && pro === '0') {
      member.style.display = 'none';
      part_member.style.display = 'block';
    }

    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const handleLeftButtonClick = () => {
    navigate('/milestone7');
  };
  const handleRightButtonClick = () => {
    PageRecord();
    navigate('/milestone9');
  };

  const PageRecord = () => {
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const postData = {
      MEMRFNBR: cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2),
      Page: '9',
      NiceCatch: access_token,
    };
    const encrypt = cryptoHandler.encrypt(JSON.stringify(postData), 2);
    const request = {
      Request: encrypt,
    };
    const request_json = JSON.stringify(request);
    const headers = {
      'Content-Type': 'application/json',
    };
    httpHandler.post(
      '/api/EventSpace/PageRecord',
      request_json,
      { headers },
      2
    );
  };

  const formatNumberString = (numberString) => {
    const number = parseInt(numberString, 10); // 將字串轉換為數字
    return number.toLocaleString(); // 格式化為每三位加逗號的格式
  };

  return (
    <>
      <div
        className="milestone-main"
        id="part-member"
        style={{ display: 'none' }}
      >
        <div className="milestone8">
          <div className="logo">
            <img src="/images/logo.png" alt="Logo" />
          </div>
          <a id="milestone8ArrowLeft" onClick={() => handleLeftButtonClick()}>
            <div className="arrowLeft"></div>
          </a>
          <a id="milestone8ArrowRight" onClick={() => handleRightButtonClick()}>
            <div className="arrowRight"></div>
          </a>
          <div className="box1">
            <div className="number">
              <img src="/images/number_8.png" alt="" />
            </div>
            <div className="text word-mileorange">
              <p>
                聰明的太空人怎麼能錯過iRent PRO，路邊租還的「PRO可還車停車場」
              </p>
              <p>及同站租還的「PRO預約清潔服務」兩大加值服務，</p>
              <p>一起來當銀河最PRO的太空人!</p>
            </div>
          </div>
          <div className="circle-container">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle full"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      </div>
      <div className="milestone-main" id="member">
        <div className="milestone8">
          <div className="logo">
            <img src="/images/logo.png" alt="Logo" />
          </div>
          <a id="milestone8ArrowLeft" onClick={() => handleLeftButtonClick()}>
            <div className="arrowLeft"></div>
          </a>
          <a id="milestone8ArrowRight" onClick={() => handleRightButtonClick()}>
            <div className="arrowRight"></div>
          </a>
          <div className="box1">
            <div className="number">
              <img src="/images/number_8.png" alt="" />
            </div>
            <div className="text">
              <p>
                這一年你加購了&nbsp;
                <span className="word-mileorange nowrap">
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).InsuranceCount
                  )}
                </span>
                &nbsp;次安心服務
              </p>
              <p>
                透過訂閱制搭乘&nbsp;
                <span className="word-mileorange nowrap">
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).MonthlyCount
                  )}
                </span>
                &nbsp;趟超划算的星際旅行
              </p>
              <p>
                使用了iRent PRO&nbsp;
                <span className="word-mileorange nowrap">
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).ProCount
                  )}
                </span>
                &nbsp;次
              </p>
              <p>2024年不管出遊或約會，你都是最聰明的銀河冒險家 !</p>
              <div className="box2 word-mileorange">
                <p>
                  聰明的太空人怎麼能錯過iRent PRO，路邊租還的「PRO可還車停車場」
                </p>
                <p>及同站租還的「PRO預約清潔服務」兩大加值服務，</p>
                <p>一起來當銀河最PRO的太空人!</p>
              </div>
            </div>
          </div>
          <div className="circle-container">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle full"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MileStone8;
