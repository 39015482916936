import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { CryptoHandler } from 'shared/cryptoHandler';

const MileStone7Not = () => {
  const navigate = useNavigate();
  const cryptoHandler = new CryptoHandler();
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const handleLeftButtonClick = () => {
    navigate('/milestone6notmember');
  };

  const handleRightButtonClick = () => {
    navigate('/milestone8notmember');
  };

  const formatNumberString = (numberString) => {
    const number = parseInt(numberString, 10); // 將字串轉換為數字
    return number.toLocaleString(); // 格式化為每三位加逗號的格式
  };

  return (
    <div className="milestone-main">
      <div className="milestone7">
        <div className="logo">
          <img src="/images/logo.png" alt="Logo" />
        </div>
        <a id="milestone7ArrowLeft" onClick={() => handleLeftButtonClick()}>
          <div className="arrowLeft"></div>
        </a>
        <a id="milestone7ArrowRight" onClick={() => handleRightButtonClick()}>
          <div className="arrowRight"></div>
        </a>
        <div className="box1">
          <div className="number">
            <img src="/images/number_7.png" alt="" />
          </div>
          <div className="text word-mileorange">
            <p>
              2024年全體iRent會員一共減碳達成&nbsp;
              <span className="word-white nowrap">
                {formatNumberString(
                  JSON.parse(
                    cryptoHandler.decrypt(
                      localStorage.getItem('AllMemberData'),
                      2
                    )
                  ).TotalCarbonReduceTree
                )}
              </span>
              &nbsp;棵樹木，
            </p>
            <p>每一棵小樹都是對地球的守護，綠色星球這條路上我們一起努力</p>
          </div>
        </div>
        <div className="circle-container">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle full"></div>
          <div className="circle"></div>
          <div className="circle"></div>
        </div>
      </div>
    </div>
  );
};

export default MileStone7Not;
