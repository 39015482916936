import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { HttpHandler } from 'shared/httpHandler';
import { CryptoHandler } from '../../../shared/cryptoHandler';

const MileStone7 = () => {
  const httpHandler = new HttpHandler();
  const cryptoHandler = new CryptoHandler();
  const navigate = useNavigate();
  useEffect(() => {
    const eletric = JSON.parse(
      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
    ).PrimaryEnergyCarCount;

    const oil = JSON.parse(
      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
    ).HybridElectricCarCount;

    const motor = JSON.parse(
      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
    ).ElectricMotorCount;

    const part_member = document.getElementById('part-member');
    const member = document.getElementById('member');

    if (eletric === '0' && oil === '0' && motor === '0') {
      member.style.display = 'none';
      part_member.style.display = 'block';
    }

    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const handleLeftButtonClick = () => {
    navigate('/milestone6');
  };
  const handleRightButtonClick = () => {
    PageRecord();
    navigate('/milestone8');
  };

  const formatNumberString = (numberString) => {
    const number = parseInt(numberString, 10); // 將字串轉換為數字
    return number.toLocaleString(); // 格式化為每三位加逗號的格式
  };

  const PageRecord = () => {
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const postData = {
      MEMRFNBR: cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2),
      Page: '8',
      NiceCatch: access_token,
    };
    const encrypt = cryptoHandler.encrypt(JSON.stringify(postData), 2);
    const request = {
      Request: encrypt,
    };
    const request_json = JSON.stringify(request);
    const headers = {
      'Content-Type': 'application/json',
    };
    httpHandler.post(
      '/api/EventSpace/PageRecord',
      request_json,
      { headers },
      2
    );
  };

  return (
    <>
      <div
        className="milestone-main"
        id="part-member"
        style={{ display: 'none' }}
      >
        <div className="milestone7">
          <div className="logo">
            <img src="/images/logo.png" alt="Logo" />
          </div>
          <a id="milestone7ArrowLeft" onClick={() => handleLeftButtonClick()}>
            <div className="arrowLeft"></div>
          </a>
          <a id="milestone7ArrowRight" onClick={() => handleRightButtonClick()}>
            <div className="arrowRight"></div>
          </a>
          <div className="box1">
            <div className="number">
              <img src="/images/number_7.png" alt="" />
            </div>
            <div className="text word-mileorange">
              <p>
                2024年全體iRent會員一共減碳達成&nbsp;
                <span className="word-white nowrap">
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(
                        localStorage.getItem('AllMemberData'),
                        2
                      )
                    ).TotalCarbonReduceTree
                  )}
                </span>
                &nbsp;棵樹木，
              </p>
              <p>每一棵小樹都是對地球的守護，綠色星球這條路上我們一起努力</p>
            </div>
          </div>
          <div className="circle-container">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle full"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      </div>
      <div className="milestone-main" id="member">
        <div className="milestone7">
          <div className="logo">
            <img src="/images/logo.png" alt="Logo" />
          </div>
          <a id="milestone7ArrowLeft" onClick={() => handleLeftButtonClick()}>
            <div className="arrowLeft"></div>
          </a>
          <a id="milestone7ArrowRight" onClick={() => handleRightButtonClick()}>
            <div className="arrowRight"></div>
          </a>
          <div className="box1">
            <div className="number">
              <img src="/images/number_7.png" alt="" />
            </div>
            <div className="text">
              <p>
                2024年你一共租借了一級能源車&nbsp;
                <span className="word-mileorange">
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).PrimaryEnergyCarCount
                  )}
                </span>
                &nbsp;次、油電車&nbsp;
                <span className="word-mileorange">
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).HybridElectricCarCount
                  )}
                </span>
                &nbsp;次、電動機車&nbsp;
                <span className="word-mileorange">
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).ElectricMotorCount
                  )}
                </span>
                &nbsp;次，換算成減碳樹木的話達到&nbsp;
                <span className="word-mileorange">
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).CarbonReduceTree
                  )}
                </span>
                &nbsp;棵
              </p>
              <p>
                即使世界有時候很灰暗，感謝你持續選擇綠色出遊，讓我們一起守護愛地球
              </p>
            </div>
          </div>
          <div className="box2 word-mileorange">
            <p>
              2024年全體iRent會員一共減碳達成&nbsp;
              <span className="word-white nowrap">
                {formatNumberString(
                  JSON.parse(
                    cryptoHandler.decrypt(
                      localStorage.getItem('AllMemberData'),
                      2
                    )
                  ).TotalCarbonReduceTree
                )}
              </span>
              &nbsp;棵樹木，
            </p>
            <p>每一棵小樹都是對地球的守護，綠色星球這條路上我們一起努力</p>
          </div>
          <div className="circle-container">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle full"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MileStone7;
