import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { CryptoHandler } from 'shared/cryptoHandler';

const MileStone6Not = () => {
  const navigate = useNavigate();
  const cryptoHandler = new CryptoHandler();
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const handleLeftButtonClick = () => {
    navigate('/milestone5notmember');
  };
  const handleRightButtonClick = () => {
    navigate('/milestone7notmember');
  };
  return (
    <div className="milestone-main">
      <div className="milestone6">
        <div className="logo">
          <img src="/images/logo.png" alt="Logo" />
        </div>
        <a id="milestone6ArrowLeft" onClick={() => handleLeftButtonClick()}>
          <div className="arrowLeft"></div>
        </a>
        <a id="milestone6ArrowRight" onClick={() => handleRightButtonClick()}>
          <div className="arrowRight"></div>
        </a>
        <div className="box1">
          <div className="number">
            <img src="/images/number_6.png" alt="" />
          </div>
          <div className="text word-mileorange">
            <p className="word-mileorange">
              星際冷知識 : 2024年最多太空人預約造訪的是&nbsp;
              <span className="word-white">
                {
                  JSON.parse(
                    cryptoHandler.decrypt(
                      localStorage.getItem('AllMemberData'),
                      2
                    )
                  ).TotalOrderLocation
                }
              </span>
              &nbsp;!
            </p>
          </div>
        </div>
        <div className="circle-container">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle full"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
        </div>
      </div>
    </div>
  );
};

export default MileStone6Not;
