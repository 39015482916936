import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga4';

// Google Tag Manager設定
const tagManagerArgs = {
  gtmId: 'GTM-WV2S43DV',
};
TagManager.initialize(tagManagerArgs);

// Google Analytics設定
ReactGA.initialize('G-Q3HV7HS4YN');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
