import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

const MileStone1Not = () => {
  const navigate = useNavigate();
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const handleLeftButtonClick = () => {
    navigate('/logininfo');
  };
  const handleRightButtonClick = () => {
    navigate('/milestone2notmember');
  };
  return (
    <div className="milestone-main">
      <div className="milestone1">
        <div className="logo">
          <img src="/images/logo.png" alt="Logo" />
        </div>
        <a id="milestone1ArrowLeft" onClick={() => handleLeftButtonClick()}>
          <div className="arrowLeft"></div>
        </a>
        <a id="milestone1ArrowRight" onClick={() => handleRightButtonClick()}>
          <div className="arrowRight"></div>
        </a>
        <div className="box1">
          <div className="number">
            <img src="/images/number_1.png" alt="" />
          </div>
          <div className="text word-mileorange">
            <p>iRent創立於2014年，推出「24小時自助無人租車服務」</p>
            <p>2019年再首創結合「四輪＋二輪」，提供共享汽機車</p>
            <p>為全台最大共享車品牌。</p>
          </div>
        </div>
        <div className="circle-container">
          <div className="circle full"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
        </div>
      </div>
    </div>
  );
};

export default MileStone1Not;
