import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { HttpHandler } from 'shared/httpHandler';
import { CryptoHandler } from '../../../shared/cryptoHandler';

const MileStone6 = () => {
  const httpHandler = new HttpHandler();
  const cryptoHandler = new CryptoHandler();
  const navigate = useNavigate();
  useEffect(() => {
    const rentdate = JSON.parse(
      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
    ).FirstOrderTime;

    const part_member = document.getElementById('part-member');
    const member = document.getElementById('member');

    if (rentdate === '') {
      member.style.display = 'none';
      part_member.style.display = 'block';
    }

    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const handleLeftButtonClick = () => {
    navigate('/milestone5');
  };
  const handleRightButtonClick = () => {
    PageRecord();
    navigate('/milestone7');
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString); // 將字串轉為日期物件
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // 月份從 0 開始計數，所以要加 1
    const day = date.getDate();
    return `${year}/${month}/${day}`; // 返回格式化的日期字串
  };

  const PageRecord = () => {
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const postData = {
      MEMRFNBR: cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2),
      Page: '7',
      NiceCatch: access_token,
    };
    const encrypt = cryptoHandler.encrypt(JSON.stringify(postData), 2);
    const request = {
      Request: encrypt,
    };
    const request_json = JSON.stringify(request);
    const headers = {
      'Content-Type': 'application/json',
    };
    httpHandler.post(
      '/api/EventSpace/PageRecord',
      request_json,
      { headers },
      2
    );
  };

  return (
    <>
      <div
        className="milestone-main"
        id="part-member"
        style={{ display: 'none' }}
      >
        <div className="milestone6">
          <div className="logo">
            <img src="/images/logo.png" alt="Logo" />
          </div>
          <a id="milestone6ArrowLeft" onClick={() => handleLeftButtonClick()}>
            <div className="arrowLeft"></div>
          </a>
          <a id="milestone6ArrowRight" onClick={() => handleRightButtonClick()}>
            <div className="arrowRight"></div>
          </a>
          <div className="box1">
            <div className="number">
              <img src="/images/number_6.png" alt="" />
            </div>
            <div className="text word-mileorange">
              <p className="word-mileorange">
                星際冷知識 : 2024年最多太空人預約造訪的是&nbsp;
                <span className="word-white">
                  {
                    JSON.parse(
                      cryptoHandler.decrypt(
                        localStorage.getItem('AllMemberData'),
                        2
                      )
                    ).TotalOrderLocation
                  }
                </span>
                &nbsp;!
              </p>
            </div>
          </div>
          <div className="circle-container">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle full"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      </div>
      <div className="milestone-main" id="member">
        <div className="milestone6">
          <div className="logo">
            <img src="/images/logo.png" alt="Logo" />
          </div>
          <a id="milestone6ArrowLeft" onClick={() => handleLeftButtonClick()}>
            <div className="arrowLeft"></div>
          </a>
          <a id="milestone6ArrowRight" onClick={() => handleRightButtonClick()}>
            <div className="arrowRight"></div>
          </a>
          <div className="box1">
            <div className="number">
              <img src="/images/number_6.png" alt="" />
            </div>
            <div className="text">
              <p>
                你於&nbsp;
                <span className="word-mileorange">
                  {formatDate(
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).FirstOrderTime
                  )}
                </span>
                &nbsp;在iRent App按下預約租車鍵，
              </p>
              <p>
                從此開始了共享移動新生活。你還記得那一天在&nbsp;
                <span className="word-mileorange">
                  {
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).FirstOrderLocation
                  }
                </span>
                &nbsp;有什麼精彩回憶嗎?
              </p>
              <p className="word-mileorange">
                星際冷知識 : 2024年最多太空人預約造訪的是&nbsp;
                <span className="word-white">
                  {
                    JSON.parse(
                      cryptoHandler.decrypt(
                        localStorage.getItem('AllMemberData'),
                        2
                      )
                    ).TotalOrderLocation
                  }
                </span>
                &nbsp;!
              </p>
            </div>
          </div>
          <div className="circle-container">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle full"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MileStone6;
