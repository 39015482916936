import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { HttpHandler } from 'shared/httpHandler';
import { useNavigate } from 'react-router-dom';
import { CryptoHandler } from '../../shared/cryptoHandler';

const RankList = () => {
  const cryptoHandler = new CryptoHandler();
  const navigate = useNavigate();
  const httpHandler = new HttpHandler();
  const [invit, setInvit] = useState(0);
  const rank = JSON.parse(
    cryptoHandler.decrypt(localStorage.getItem('RankData'), 2)
  );
  rank.sort((a, b) => b.Rank - a.Rank);
  const chinese_rank = [
    '一',
    '二',
    '三',
    '四',
    '五',
    '六',
    '七',
    '八',
    '九',
    '十',
    '十一',
    '十二',
    '十三',
    '十四',
    '十五',
    '十六',
    '十七',
    '十八',
    '十九',
    '二十',
  ];
  useEffect(() => {
    // 在組件掛載時設置 body 的 class
    document.body.className = 'milestone10_2';

    // GA4追蹤頁面瀏覽
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });

    // 在組件卸載時清除 body 的 class
    return () => {
      document.body.className = '';
    };
  }, []);

  useEffect(() => {
    // 取得排行榜資料
    for (let i = 0; i < rank.length; i++) {
      if (
        rank[i].MEMRFNBR ===
        cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2)
      ) {
        setInvit(rank[i].InviteNum);
      }
    }
  }, []);

  // 動態調整邀請人數圖片
  const getImageSrc = () => {
    if (invit >= 21) return './images/milestone10_2_full_As.png';
    if (invit <= 20 && invit >= 1) return './images/milestone10_2_selAs.png';
    return './images/milestone10_2_noAs.png';
  };

  const handleGoHomeButtonClick = () => {
    // TODO 跳轉首頁
    if (localStorage.getItem('Inviter') !== '') {
      navigate(`/home/index?MEMRFNBR=${localStorage.getItem('Inviter')}`);
    } else if (localStorage.getItem('Method') !== null) {
      navigate(`/home/?Token=${localStorage.getItem('Method')}`);
    } else {
      navigate(`/home`);
    }
  };

  const handleLeftButtonClick = () => {
    navigate('/member_result_stat');
  };

  return (
    <>
      <a id="milestone1ArrowLeft" onClick={() => handleLeftButtonClick()}>
        <div className="arrowLeft"></div>
      </a>
      <div className="logo">
        <img src="./images/logo.png" alt="" />
      </div>
      <div className="box-content">
        <div className="right1">
          <div className="title">
            <img src="./images/Mainword_W.png" alt="" />
          </div>
          <div className="word-mile10_2white_title">
            邀請活動 - 目前邀請人數：
            <span className="word-mileorange10_2"> {invit} </span> 人
          </div>
        </div>
        <div className="planet">
          {/* 分享人數多少更換圖 */}
          <img src={getImageSrc()} alt="Level" />
        </div>
        <div className="right2">
          <div>
            <p className="word-mile10_2white">活動排行榜：</p>
            <p className="word-mile10_2white">
              邀請好友一起體驗活動，就有機會收到來自宇宙的漂流信!地表最強前20名，宇宙限量專屬毛巾送給你
              !
            </p>
          </div>
          <div className="table_title">活動排行榜</div>
          <div className="table_box">
            {rank.slice(0, 20).map((entry, index) => {
              // 如果 InviterNum 為 0，則不渲染
              if (entry.Rank === '0') {
                return null;
              }

              return (
                <div
                  className={
                    entry.MEMRFNBR ===
                    cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2)
                      ? 'table_content word-mile10table'
                      : 'table_content'
                  }
                  key={index}
                >
                  <span>
                    第{chinese_rank[index]}名&nbsp;{entry.MEMRFNBR}
                  </span>
                  <span>邀請人數 {entry.InviteNum} 人</span>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="box-content2">
        <div className="title">活動辦法</div>
        <div className="content">
          {/* {JSON.parse(localStorage.getItem('AllMemberData')).RankNoting} */}
          《注意事項》
          <br />
          <span className="message">
            ⚫&nbsp;本活動由和雲行動服務(以下簡稱「主辦單位」)主辦，參與者參加本活動同
            時，即同意接受本活動「注意事項」、「個人資料告知事項」之規範，如
            有任何違反之情事，主辦單位得取消其中獎資格，並對於任何破壞本活動
            之行為行使法律追訴權。
            <br />
          </span>
          <span className="message">
            ⚫&nbsp;具有得獎資格之iRent會員帳號(同身分證字號)、手機號碼需為真實身分，
            活動應由本人親自參與，不得由他人代為參加，不得為人頭帳號、假帳
            號，若使用者以人頭帳號或電腦駭客程式等不正當之手段參與活動，經主
            辦單位發現或經第三人檢舉，則主辦單位有權取消使用者之參與、得獎資
            格，主辦單位不另行通知，並保留法律追訴權。
            <br />
          </span>
          <span className="message">
            ⚫&nbsp;主辦單位保留修改活動及獎品、審查參與者及中獎者資格等之權利，並保
            留隨時取消、終止、修改或暫停與調整活動內容、活動辦法、注意事項、
            獎項及領獎方式等相關細節之權利，無須作事前通知，亦有權對本活動之
            所有事宜作出補充、解釋或裁決。
            <br />
          </span>
          <span className="message">
            若因本活動發生訴訟時，以台灣台北地方法院為第一審管轄法院。
          </span>
          <br />
          <br />
          《專屬連結邀請會員體驗-排行榜》
          <br />
          <span className="message">
            ⚫&nbsp;活動資格：邀請者、被邀請者皆需為iRent有效會員。
            <br />
          </span>
          <span className="message">
            ⚫&nbsp;活動時間 : 即日起~2024/12/31(二)
            <br />
          </span>
          <span className="message">
            ⚫&nbsp;活動辦法：活動期間會員完成回顧後查看個人結果頁面並從活動網頁分享專屬連結
            <br />
          </span>
          <span className="message">
            1.&nbsp;邀請其他iRent會員一同參與本活動網頁並完成體驗。
            <br />
          </span>
          <span className="message">
            2.&nbsp;當雙方皆完成活動體驗後即計算為完成一次任務。
            <br />
          </span>
          <span className="message">
            3.&nbsp;於活動期間完成任務次數最多的會員前20名可獲得限量贈品。(如完成次數相同則以完成時間優先決定名次)
            <br />
          </span>
          <span className="message">
            4.&nbsp;會員可由活動頁面之排行榜確認目前個人分享次數。
            <br />
          </span>
          <span className="message">
            ⚫&nbsp;活動獎品 :《宇宙漂流信》實體毛巾乙份 (限量20份)
            <br />
          </span>
          <span className="message">
            1.&nbsp;獎項發放時間 : 於2025/2/28前寄出。
            <br />
          </span>
          <span className="message">
            2.&nbsp;《宇宙漂流信》實體毛巾係指-會員於活動網頁”宇宙漂流信”單元中留下之祝福文字，印製為實體毛巾圖案。
            <br />
          </span>
          <span className="message">
            ⚫&nbsp;本活動嚴禁留下違反善良風俗、人身攻擊、不雅內容及不法之訊息，如有發現將有權刪除任何不妥之內容。
            <br />
          </span>
          <span className="message">
            ⚫&nbsp;如最終獲獎者之活動留言訊息經本公司判斷有不適言論，本公司活動小組擁有不經告知逕自變更文案之權利。
            <br />
          </span>
          <span className="message">
            ⚫&nbsp;禁止統獨、政治、族群、商業廣告或其他任何不法之文字內容。
            <br />
          </span>
          <span className="message">
            3.&nbsp;參與者同意所留存或產生之任何參與本活動的資料或記錄，皆以主辦單
            位之電腦系統與時間紀錄為主。本活動如任何因電腦、網路、電話、技
            術或其他不可歸責於主辦單位之事由，而使參與者所登錄、填寫或寄出
            之資料有延遲、遺失、錯誤、無法辨識、毀損或無效之狀況，主辦單位
            不負任何法律責任，參與者與中獎者亦不得異議。
            <br />
          </span>
          <br />
          <br />
          和雲行動服務股份有限公司保留本活動內容調整、取消、終止及最終解釋之權利，如有任何疑問(包含個人資料告知事項)，請透過文字客服或撥打02-5589-0808客服專線與主辦單位聯絡。
        </div>
      </div>
      <a id="memberBtnBackToIndex" onClick={() => handleGoHomeButtonClick()}>
        <div className="backtoindex">
          <span className="width85">回首頁</span>
          <img
            src="./images/white_arrow_right.gif"
            alt=""
            className="width20"
            style={{ margin: '0 5px' }}
          />
        </div>
      </a>
      <div style={{ height: '80px' }}></div>
      <div className="circle-container">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle full"></div>
      </div>
    </>
  );
};

export default RankList;
