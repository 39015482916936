import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { HttpHandler } from 'shared/httpHandler';
import { CryptoHandler } from '../../../shared/cryptoHandler';

const MileStone5 = () => {
  const httpHandler = new HttpHandler();
  const cryptoHandler = new CryptoHandler();
  const navigate = useNavigate();
  useEffect(() => {
    const min = JSON.parse(
      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
    ).RentMin;
    const max = JSON.parse(
      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
    ).RentMinMax;

    const part_member = document.getElementById('part-member');
    const member = document.getElementById('member');

    if (min === '0' && max === '0') {
      member.style.display = 'none';
      part_member.style.display = 'block';
    }

    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const handleLeftButtonClick = () => {
    navigate('/milestone4');
  };
  const handleRightButtonClick = () => {
    PageRecord();
    navigate('/milestone6');
  };

  const PageRecord = () => {
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const postData = {
      MEMRFNBR: cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2),
      Page: '6',
      NiceCatch: access_token,
    };
    const encrypt = cryptoHandler.encrypt(JSON.stringify(postData), 2);
    const request = {
      Request: encrypt,
    };
    const request_json = JSON.stringify(request);
    const headers = {
      'Content-Type': 'application/json',
    };
    httpHandler.post(
      '/api/EventSpace/PageRecord',
      request_json,
      { headers },
      2
    );
  };

  const formatNumberString = (numberString) => {
    const number = parseInt(numberString, 10); // 將字串轉換為數字
    return number.toLocaleString(); // 格式化為每三位加逗號的格式
  };

  return (
    <>
      <div
        className="milestone-main"
        id="part-member"
        style={{ display: 'none' }}
      >
        <div className="milestone5">
          <div className="logo">
            <img src="/images/logo.png" alt="Logo" />
          </div>
          <a id="milestone5ArrowLeft" onClick={() => handleLeftButtonClick()}>
            <div className="arrowLeft"></div>
          </a>
          <a id="milestone5ArrowRight" onClick={() => handleRightButtonClick()}>
            <div className="arrowRight"></div>
          </a>
          <div className="box1">
            <div className="number">
              <img src="/images/number_5.png" alt="" />
            </div>
            <div className="text word-mileorange">
              <p>
                從服務開展以來，
                <span className="nowrap">
                  iRent已經陪伴大家&nbsp;
                  <span className="word-white">
                    {formatNumberString(
                      JSON.parse(
                        cryptoHandler.decrypt(
                          localStorage.getItem('AllMemberData'),
                          2
                        )
                      ).TotalRentMin
                    )}
                  </span>
                  &nbsp;分鐘，
                </span>
              </p>
              <p>下一個十年依舊在這裡陪您渡過每個重要時刻。</p>
            </div>
          </div>
          <div className="circle-container">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle full"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      </div>
      <div className="milestone-main" id="member">
        <div className="milestone5">
          <div className="logo">
            <img src="/images/logo.png" alt="Logo" />
          </div>
          <a id="milestone5ArrowLeft" onClick={() => handleLeftButtonClick()}>
            <div className="arrowLeft"></div>
          </a>
          <a id="milestone5ArrowRight" onClick={() => handleRightButtonClick()}>
            <div className="arrowRight"></div>
          </a>
          <div className="box1">
            <div className="number">
              <img src="/images/number_5.png" alt="" />
            </div>
            <div className="text">
              <p>
                今年你在iRent太空船裡面待了&nbsp;
                <span className="word-mileorange">
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).RentMin
                  )}
                </span>
                &nbsp;分鐘，最高曾經一天待了&nbsp;
                <span className="word-mileorange">
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).RentMinMax
                  )}
                </span>
                &nbsp;分鐘。
              </p>
              <p>據說在遙遠的星球上一分鐘等於地球的好幾年，</p>
              <p>但不論時間長短，iRent號永遠都在這裡陪你。</p>
            </div>
          </div>
          <div className="box2 word-mileorange">
            <p>
              從服務開展以來，iRent已經陪伴大家&nbsp;
              <span className="word-white">
                {formatNumberString(
                  JSON.parse(
                    cryptoHandler.decrypt(
                      localStorage.getItem('AllMemberData'),
                      2
                    )
                  ).TotalRentMin
                )}
              </span>
              &nbsp;分鐘，
            </p>
            <p>下一個十年依舊在這裡陪您渡過每個重要時刻。</p>
          </div>
          <div className="circle-container">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle full"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MileStone5;
