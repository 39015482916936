import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { HttpHandler } from 'shared/httpHandler';
import { useNavigate, useLocation } from 'react-router-dom';
import { message } from 'antd';
import { QRCodeSVG } from 'qrcode.react';
import '../../css/milestone10.css';

const NotMemberResult = () => {
  const navigate = useNavigate();
  const protocol = window.location.protocol;
  const host = window.location.hostname;
  const port = window.location.port;
  const baseUrl = `${protocol}//${host}${port ? `:${port}` : ''}`;
  const sharelink = baseUrl + '/Index';
  // 複製成功對話框
  const handleCopyButton = (text, type) => () => {
    const msg = type === 1 ? '已複製折扣碼' : '已複製活動網址';
    navigator.clipboard.writeText(text).then(() => {
      message.success({
        content: msg,
        style: {
          fontStyle: 'normal',
        },
      });
    });
  };

  const handleGoHomeButton = () => {
    if (localStorage.getItem('Inviter') !== '') {
      navigate(`/home/index?MEMRFNBR=${localStorage.getItem('Inviter')}`);
    } else if (localStorage.getItem('Method') !== null) {
      navigate(`/home/?Token=${localStorage.getItem('Method')}`);
    } else {
      navigate(`/home`);
    }
  };

  useEffect(() => {
    // 在組件掛載時設置 body 的 clas
    document.body.className = 'milestone10_3';

    // GA4追蹤頁面瀏覽
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });

    // 在組件卸載時清除 body 的 class
    return () => {
      document.body.className = '';
    };
  }, []);

  const handleLeftButtonClick = () => {
    navigate('/milestone8notmember');
  };

  const handleQrcode = () => {
    const link = 'https://www.irentcar.com.tw/irent/web/index.html#app';
    window.location.href = link;
  };

  return (
    <>
      <a id="milestone1ArrowLeft" onClick={() => handleLeftButtonClick()}>
        <div className="arrowLeft"></div>
      </a>
      <div className="logo">
        <img src="./images/logo.png" alt="" />
      </div>
      <div className="box1">
        <div className="number" />
        <div>
          <p>
            恭喜你成功完成這趟太空之旅! 偷偷告訴你現在加入iRent會員，
            <span className="word-mileorange">
              輸入 「宇你同行」
              新會員限定兌換碼，還可獲得汽車60分鐘折抵券1張+機車前6分鐘折抵券2張的太空人大禮包。
            </span>
          </p>
          <p>期待與你相見，現在就立即加入會員吧 !</p>
        </div>
      </div>
      <div className="box-content">
        <div className="astronut">
          <img src="./images/milestone10_3_asQrcode.png" alt="" />
          <a onClick={handleQrcode}>
            <QRCodeSVG
              value="https://www.irentcar.com.tw/irent/web/index.html#app"
              className="qrcode"
              size={180}
              fgColor="#000000"
            />
          </a>
        </div>
        <div className="text">
          <div className="word-white">
            新會員折扣碼<span className="word-discount">宇你同行</span>
            <span
              className="buttonRed"
              id="btnDiscountCopy"
              onClick={handleCopyButton('宇你同行', 1)}
            >
              複製
            </span>
          </div>
          <div className="word-white" style={{ marginTop: '5px' }}>
            分享活動網址
            <span className="nowrap">
              <span className="word-url" id="btnUrlCopy">
                {sharelink}
              </span>
              <span
                className="buttonRed"
                onClick={handleCopyButton(sharelink, 2)}
              >
                複製
              </span>
            </span>
          </div>
        </div>

        <a id="notMemberBackToIndex" onClick={() => handleGoHomeButton()}>
          <div className="backtoindex">
            <span className="width85">回首頁</span>
            <img
              src="./images/white_arrow_right.gif"
              alt=""
              className="width20"
              style={{ margin: '0 5px' }}
            />
          </div>
        </a>
      </div>
      <div className="circle-container">
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle"></div>
        <div className="circle full"></div>
      </div>
    </>
  );
};

export default NotMemberResult;
