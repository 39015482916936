import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { HttpHandler } from 'shared/httpHandler';
import { CryptoHandler } from '../../../shared/cryptoHandler';

const MileStone1 = () => {
  const httpHandler = new HttpHandler();
  const cryptoHandler = new CryptoHandler();
  const navigate = useNavigate();
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const handleLeftButtonClick = () => {
    navigate('/logininfo');
  };
  const handleRightButtonClick = () => {
    PageRecord();
    navigate('/milestone2');
  };

  const PageRecord = () => {
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const postData = {
      MEMRFNBR: cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2),
      Page: '2',
      NiceCAtch: access_token,
    };
    const encrypt = cryptoHandler.encrypt(JSON.stringify(postData), 2);
    const request = {
      Request: encrypt,
    };
    const request_json = JSON.stringify(request);
    const headers = {
      'Content-Type': 'application/json',
    };
    httpHandler.post(
      '/api/EventSpace/PageRecord',
      request_json,
      { headers },
      2
    );
  };

  const formatDate = (dateString) => {
    if (dateString !== '') {
      const date = new Date(dateString); // 將字串轉為日期物件
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // 月份從 0 開始計數，所以要加 1
      const day = date.getDate();
      return `${year}/${month}/${day}`; // 返回格式化的日期字串
    } else {
      return '好多年以前';
    }
  };

  return (
    <div className="milestone-main">
      <div className="milestone1">
        <div className="logo">
          <img src="/images/logo.png" alt="Logo" />
        </div>
        <a id="milestone1ArrowLeft" onClick={() => handleLeftButtonClick()}>
          <div className="arrowLeft"></div>
        </a>
        <a id="milestone1ArrowRight" onClick={() => handleRightButtonClick()}>
          <div className="arrowRight"></div>
        </a>
        <div className="box1">
          <div className="number">
            <img src="/images/number_1.png" alt="" />
          </div>
          <div className="text">
            <p>
              親愛的太空人&nbsp;
              <span className="word-mileorange">
                {
                  JSON.parse(
                    cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                  ).MEMCNAME
                }
              </span>
            </p>
            <p>
              很榮幸你在&nbsp;
              <span className="word-mileorange">
                {formatDate(
                  JSON.parse(
                    cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                  ).AuditPassDate
                )}
              </span>
              &nbsp;加入我們的行列
            </p>
            <p>冒險的起點，一切都是從這裡開始的……</p>
          </div>
        </div>
        <div className="box2 word-mileorange">
          <p>iRent創立於2014年，推出「24小時自助無人租車服務」</p>
          <p>2019年再首創結合「四輪＋二輪」，提供共享汽機車</p>
          <p>為全台最大共享車品牌。</p>
        </div>
        <div className="circle-container">
          <div className="circle full"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
        </div>
      </div>
    </div>
  );
};

export default MileStone1;
