import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { HttpHandler } from 'shared/httpHandler';
import { CryptoHandler } from '../../shared/cryptoHandler';

const LoginInfo = () => {
  const httpHandler = new HttpHandler();
  const cryptoHandler = new CryptoHandler();
  const navigate = useNavigate();
  const [isMember, setIsMember] = useState(false);

  useEffect(() => {
    // GA4追蹤頁面瀏覽
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  // 如果Session內有token且token有效，則需顯示排行榜按鈕，以及跳過會員登入流程
  useEffect(() => {
    if (localStorage.getItem('MEMRFNBR')) {
      setIsMember(true);
    }
  });

  const handleGoButtonClick = async (isIrentMember) => {
    // 點選我是會員
    if (isIrentMember) {
      if (isMember) {
        // 有EventToken就取得個別會員資料
        await GetMemberInfo();

        navigate('/milestone1');
      } else {
        if (localStorage.getItem('Method')) {
          await ThirdLogin();
        } else {
          navigate('/login');
        }
      }
      // 點選我還不是會員
    } else {
      navigate('/milestone1notmember');
    }
  };

  // 有EventToken的情況會出現，前往排行榜並且取得排行榜資料
  const handleRankButtonClick = async () => {
    // 取得排行榜資料
    await Rank();

    navigate('/ranklist');
  };

  const ThirdLogin = async () => {
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const value = localStorage.getItem('Method');
    const postData = JSON.parse(cryptoHandler.decrypt(value, 2));
    postData['NiceCatch'] = access_token;
    const new_data = JSON.stringify(postData);
    const encrypt = cryptoHandler.encrypt(new_data, 2);
    const request = {
      Request: encrypt,
    };
    const request_json = JSON.stringify(request);
    const headers = {
      'Content-Type': 'application/json',
    };

    // 登入
    httpHandler
      .post('/api/EventSpace/Login', request_json, { headers })
      .then(async (response) => {
        // 解密
        const logininfo = JSON.parse(
          cryptoHandler.decrypt(response.Response, 2)
        );
        if (logininfo.Result === 1) {
          const new_mem = cryptoHandler.encrypt(logininfo.Data.MEMRFNBR, 2);
          const new_extok = cryptoHandler.encrypt(logininfo.Data.EventToken, 2);
          const new_extime = cryptoHandler.encrypt(
            logininfo.Data.ExpireTime,
            2
          );

          localStorage.setItem('MEMRFNBR', new_mem);
          localStorage.setItem('EventToken', new_extok);
          localStorage.setItem('ExpireTime', new_extime);

          // 取得個別會員資料
          await GetMemberInfo();

          navigate('/milestone1');
        } else {
          alert('帳號或密碼錯誤');
        }
      });
  };

  // 取得個別會員資料
  const GetMemberInfo = async () => {
    // 加密
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const postData = {
      MEMRFNBR: cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2),
      NiceCatch: access_token,
    };
    const encrypt = cryptoHandler.encrypt(JSON.stringify(postData), 2);
    const request = {
      Request: encrypt,
    };
    const request_json = JSON.stringify(request);
    const headers = {
      'Content-Type': 'application/json',
    };
    await httpHandler
      .post('/api/EventSpace/GetMemberData', request_json, { headers })
      .then((response) => {
        // 解密
        const info = JSON.parse(cryptoHandler.decrypt(response.Response, 2));
        const new_info = cryptoHandler.encrypt(JSON.stringify(info.Data), 2);
        localStorage.setItem('UserData', new_info);
      });
  };

  // 取得排行榜資料
  const Rank = async () => {
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const headers = {
      'Content-Type': 'application/json',
    };

    // 取得資料
    await httpHandler
      .get(`/api/EventSpace/Rank?NiceCatch=${access_token}`, { headers })
      .then((response) => {
        const data = JSON.parse(
          cryptoHandler.decrypt(response.Response, 2)
        ).Data;
        const new_rank = cryptoHandler.encrypt(JSON.stringify(data), 2);
        localStorage.setItem('RankData', new_rank);
      });
  };

  return (
    <div className="login-info-main">
      <div className="login-info-bg" />
      <div className="logo">
        <img src="./images/logo.png" alt="" />
      </div>
      <div className="container content">
        <div className="word-part">
          <div className="title">
            <img src="./images/Mainword_W.png" alt="" />
          </div>
          <div className="word-white">
            <p>
              2024年即將結束，iRent太空號帶您回顧這一路上的冒險旅程各位會員記得登入體驗以解鎖更多太空人資訊
            </p>
            <p className="word-loginorange">體驗完整活動還可獲得免費折抵券</p>
            <p className="word-loginorange">
              ( 汽車30分鐘1張+機車前6分鐘2張 )。
            </p>
          </div>
        </div>
        {/* 我還不是iRent會員 */}
        <a
          href=""
          id="notMemberBtnInfo"
          onClick={() => handleGoButtonClick(false)}
        >
          <div className="astronut-purple">
            <img src="./images/loginInfo_purpleAs.gif" alt="" />
          </div>
        </a>
      </div>
      {/* 我是iRent會員 */}
      <a id="memberBtn" onClick={() => handleGoButtonClick(true)}>
        <div className="astronut-red">
          <img src="./images/loginInfo_redAs.gif" alt="" />
        </div>
      </a>
      {/* 排行榜看板 */}
      {isMember && (
        <a id="leaderboardBtn" onClick={() => handleRankButtonClick()}>
          <div className="leaderboard">
            <img src="./images/loginInfo_board.gif" alt="" />
          </div>
        </a>
      )}
    </div>
  );
};

export default LoginInfo;
