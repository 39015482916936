import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { CryptoHandler } from 'shared/cryptoHandler';

const MileStone5Not = () => {
  const navigate = useNavigate();
  const cryptoHandler = new CryptoHandler();
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const handleLeftButtonClick = () => {
    navigate('/milestone4notmember');
  };

  const handleRightButtonClick = () => {
    navigate('/milestone6notmember');
  };

  const formatNumberString = (numberString) => {
    const number = parseInt(numberString, 10); // 將字串轉換為數字
    return number.toLocaleString(); // 格式化為每三位加逗號的格式
  };

  return (
    <div className="milestone-main">
      <div className="milestone5">
        <div className="logo">
          <img src="/images/logo.png" alt="Logo" />
        </div>
        <a id="milestone5ArrowLeft" onClick={() => handleLeftButtonClick()}>
          <div className="arrowLeft"></div>
        </a>
        <a id="milestone5ArrowRight" onClick={() => handleRightButtonClick()}>
          <div className="arrowRight"></div>
        </a>
        <div className="box1">
          <div className="number">
            <img src="/images/number_5.png" alt="" />
          </div>
          <div className="text word-mileorange">
            <p>
              從服務開展以來，
              <span className="nowrap">
                iRent已經陪伴大家&nbsp;
                <span className="word-white">
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(
                        localStorage.getItem('AllMemberData'),
                        2
                      )
                    ).TotalRentMin
                  )}
                </span>
                &nbsp;分鐘，
              </span>
            </p>
            <p>下一個十年依舊在這裡陪您渡過每個重要時刻。</p>
          </div>
        </div>
        <div className="circle-container">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle full"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
        </div>
      </div>
    </div>
  );
};

export default MileStone5Not;
