import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { HttpHandler } from 'shared/httpHandler';
import { CryptoHandler } from '../../../shared/cryptoHandler';

const MileStone3 = () => {
  const httpHandler = new HttpHandler();
  const cryptoHandler = new CryptoHandler();
  const navigate = useNavigate();
  useEffect(() => {
    const city = JSON.parse(
      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
    ).OrderLocationCount;

    const part_member = document.getElementById('part-member');
    const member = document.getElementById('member');

    if (city === '0' || city === null || city === undefined || city === '') {
      member.style.display = 'none';
      part_member.style.display = 'block';
    }

    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const handleLeftButtonClick = () => {
    navigate('/milestone2');
  };
  const handleRightButtonClick = () => {
    PageRecord();
    navigate('/milestone4');
  };

  const PageRecord = () => {
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const postData = {
      MEMRFNBR: cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2),
      Page: '4',
      NiceCatch: access_token,
    };
    const encrypt = cryptoHandler.encrypt(JSON.stringify(postData), 2);
    const request = {
      Request: encrypt,
    };
    const request_json = JSON.stringify(request);
    const headers = {
      'Content-Type': 'application/json',
    };
    httpHandler.post(
      '/api/EventSpace/PageRecord',
      request_json,
      { headers },
      2
    );
  };

  const formatNumberString = (numberString) => {
    const number = parseInt(numberString, 10); // 將字串轉換為數字
    return number.toLocaleString(); // 格式化為每三位加逗號的格式
  };

  return (
    <>
      <div
        className="milestone-main"
        id="part-member"
        style={{ display: 'none' }}
      >
        <div className="milestone3">
          <div className="logo">
            <img src="/images/logo.png" alt="Logo" />
          </div>
          <a id="milestone3ArrowLeft" onClick={() => handleLeftButtonClick()}>
            <div className="arrowLeft"></div>
          </a>
          <a id="milestone3ArrowRight" onClick={() => handleRightButtonClick()}>
            <div className="arrowRight"></div>
          </a>
          <div className="box1">
            <div className="number">
              <img src="/images/number_3.png" alt="" />
            </div>
            <div className="text word-mileorange">
              <p>iRent 串連全台灣，成為首家服務據點遍布各縣市的共享業者，</p>
              <p>為推動台灣綠色交通，開啟移動新篇章。 </p>
            </div>
          </div>
          <div className="circle-container">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle full"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      </div>
      <div className="milestone-main" id="member">
        <div className="milestone3">
          <div className="logo">
            <img src="/images/logo.png" alt="Logo" />
          </div>
          <a id="milestone3ArrowLeft" onClick={() => handleLeftButtonClick()}>
            <div className="arrowLeft"></div>
          </a>
          <a id="milestone3ArrowRight" onClick={() => handleRightButtonClick()}>
            <div className="arrowRight"></div>
          </a>
          <div className="box1">
            <div className="number">
              <img src="/images/number_3.png" alt="" />
            </div>
            <div className="text">
              <p>
                今年你總共解鎖了
                <span className="word-mileorange nowrap">
                  &nbsp;
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).OrderLocationCount
                  )}
                  &nbsp;
                </span>
                個縣市的星球，我們一起闖蕩過這麼多的地方，
              </p>
              <p>
                你最情有獨鍾的是&nbsp;
                <span className="word-mileorange nowrap">
                  {
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).OrderLocationMax
                  }
                </span>
                &nbsp;，想必那邊有你難忘的回憶!
              </p>
              <p>戴上你最帥的頭盔、繫上安全帶，新的冒險一鍵啟程!</p>
              <div className="box2 word-mileorange">
                <p>iRent 串連全台灣，成為首家服務據點遍布各縣市的共享業者，</p>
                <p>為推動台灣綠色交通，開啟移動新篇章。 </p>
              </div>
            </div>
          </div>
          <div className="circle-container">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle full"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MileStone3;
