import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { HttpHandler } from 'shared/httpHandler';
import { CryptoHandler } from '../../../shared/cryptoHandler';

const MileStone2 = () => {
  const httpHandler = new HttpHandler();
  const cryptoHandler = new CryptoHandler();
  const navigate = useNavigate();
  useEffect(() => {
    const car = JSON.parse(
      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
    ).CarRentMile;
    const motor = JSON.parse(
      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
    ).MotorRentMile;

    const part_member = document.getElementById('part-member');
    const member = document.getElementById('member');

    if (car === '0' && motor === '0') {
      member.style.display = 'none';
      part_member.style.display = 'block';
    }
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const handleLeftButtonClick = () => {
    navigate('/milestone1');
  };
  const handleRightButtonClick = () => {
    PageRecord();
    navigate('/milestone3');
  };

  const PageRecord = () => {
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const postData = {
      MEMRFNBR: cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2),
      Page: '3',
      NiceCatch: access_token,
    };
    const encrypt = cryptoHandler.encrypt(JSON.stringify(postData), 2);
    const request = {
      Request: encrypt,
    };
    const request_json = JSON.stringify(request);
    const headers = {
      'Content-Type': 'application/json',
    };
    httpHandler.post(
      '/api/EventSpace/PageRecord',
      request_json,
      { headers },
      2
    );
  };

  const formatNumberString = (numberString) => {
    const number = parseInt(numberString, 10); // 將字串轉換為數字
    return number.toLocaleString(); // 格式化為每三位加逗號的格式
  };

  return (
    <>
      <div
        className="milestone-main"
        id="part-member"
        style={{ display: 'none' }}
      >
        <div className="milestone2">
          <div className="logo">
            <img src="/images/logo.png" alt="Logo" />
          </div>
          <a id="milestone2ArrowLeft" onClick={() => handleLeftButtonClick()}>
            <div className="arrowLeft"></div>
          </a>
          <a id="milestone2ArrowRight" onClick={() => handleRightButtonClick()}>
            <div className="arrowRight"></div>
          </a>
          <div className="box1">
            <div className="number">
              <img src="/images/number_2.png" alt="" />
            </div>
            <div className="text word-mileorange">
              <p>
                2024年 iRent會員們駕駛著太空梭一同創造了&nbsp;
                <span className="word-white nowrap">
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(
                        localStorage.getItem('AllMemberData'),
                        2
                      )
                    ).TotalRentMile
                  )}
                  &nbsp;
                </span>
                公里 !
              </p>
              <p>
                這幾年來累積的里程，足以從地球航行到月球，一路上的旅行慶幸有你陪伴
              </p>
              <p>讓太空艙裡充滿了兜風的談笑回憶。</p>
            </div>
          </div>
          <div className="circle-container">
            <div className="circle"></div>
            <div className="circle full"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      </div>
      <div className="milestone-main" id="member">
        <div className="milestone2">
          <div className="logo">
            <img src="/images/logo.png" alt="Logo" />
          </div>
          <a id="milestone2ArrowLeft" onClick={() => handleLeftButtonClick()}>
            <div className="arrowLeft"></div>
          </a>
          <a id="milestone2ArrowRight" onClick={() => handleRightButtonClick()}>
            <div className="arrowRight"></div>
          </a>
          <div className="box1">
            <div className="number">
              <img src="/images/number_2.png" alt="" />
            </div>
            <div className="text">
              <p>
                2024年你一共駕駛iRent汽車號航行了
                <span className="word-mileorange nowrap">
                  &nbsp;
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).CarRentMile
                  )}
                  &nbsp;
                </span>
                公里
              </p>
              <p>
                一共駕駛iRent機車號航行
                <span className="word-mileorange">
                  &nbsp;
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).MotorRentMile
                  )}
                  &nbsp;
                </span>
                公里
              </p>
              <p>在茫茫銀河系中你就是最閃亮的流星!</p>
            </div>
          </div>
          <div className="box2 word-mileorange">
            <p>
              2024年 iRent會員們駕駛著太空梭一同創造了&nbsp;
              <span className="word-white nowrap">
                {formatNumberString(
                  JSON.parse(
                    cryptoHandler.decrypt(
                      localStorage.getItem('AllMemberData'),
                      2
                    )
                  ).TotalRentMile
                )}
                &nbsp;
              </span>
              公里 !
            </p>
            <p>
              這幾年來累積的里程，足以從地球航行到月球，一路上的旅行慶幸有你陪伴
            </p>
            <p>讓太空艙裡充滿了兜風的談笑回憶。</p>
          </div>
          <div className="circle-container">
            <div className="circle"></div>
            <div className="circle full"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MileStone2;
