import React from 'react';
import { Route, Routes } from 'react-router-dom';
import Home from '../pages/basic/Home';
import LoginInfo from '../pages/basic/LoginInfo';
import Login from 'pages/basic/Login';
import RankList from '../pages/basic/RankList';
import MileStone1 from '../pages/flow/step1/milestone1';
import MileStone1Not from '../pages/flow/step1/milestone1notmember';
import MileStone2 from '../pages/flow/step2/milestone2';
import MileStone2Not from '../pages/flow/step2/milestone2notmember';
import MileStone3 from '../pages/flow/step3/milestone3';
import MileStone3Not from '../pages/flow/step3/milestone3notmember';
import MileStone4 from '../pages/flow/step4/milestone4';
import MileStone4Not from '../pages/flow/step4/milestone4notmember';
import MileStone5 from '../pages/flow/step5/milestone5';
import MileStone5Not from '../pages/flow/step5/milestone5notmember';
import MileStone6 from '../pages/flow/step6/milestone6';
import MileStone6Not from '../pages/flow/step6/milestone6notmember';
import MileStone7 from '../pages/flow/step7/milestone7';
import MileStone7Not from '../pages/flow/step7/milestone7notmember';
import MileStone8 from '../pages/flow/step8/milestone8';
import MileStone8Not from '../pages/flow/step8/milestone8notmember';
import MileStone9 from '../pages/flow/step9/milestone9';
import MemberResultStst from '../pages/basic/MemberResultStat';
import NotMemberResult from 'pages/basic/NotMemberResult';

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/home" element={<Home />}></Route>
      <Route path="/logininfo" element={<LoginInfo />}></Route>
      <Route path="/login" element={<Login />}></Route>
      <Route path="/ranklist" element={<RankList />}></Route>
      <Route path="/milestone1" element={<MileStone1 />}></Route>
      <Route path="/milestone1notmember" element={<MileStone1Not />}></Route>
      <Route path="/milestone2" element={<MileStone2 />}></Route>
      <Route path="/milestone2notmember" element={<MileStone2Not />}></Route>
      <Route path="/milestone3" element={<MileStone3 />}></Route>
      <Route path="/milestone3notmember" element={<MileStone3Not />}></Route>
      <Route path="/milestone4" element={<MileStone4 />}></Route>
      <Route path="/milestone4notmember" element={<MileStone4Not />}></Route>
      <Route path="/milestone5" element={<MileStone5 />}></Route>
      <Route path="/milestone5notmember" element={<MileStone5Not />}></Route>
      <Route path="/milestone6" element={<MileStone6 />}></Route>
      <Route path="/milestone6notmember" element={<MileStone6Not />}></Route>
      <Route path="/milestone7" element={<MileStone7 />}></Route>
      <Route path="/milestone7notmember" element={<MileStone7Not />}></Route>
      <Route path="/milestone8" element={<MileStone8 />}></Route>
      <Route path="/milestone8notmember" element={<MileStone8Not />}></Route>
      <Route path="/milestone9" element={<MileStone9 />}></Route>
      <Route path="/member_result_stat" element={<MemberResultStst />}></Route>
      <Route path="/not_member_result" element={<NotMemberResult />}></Route>
      <Route path="/*" element={<Home />}></Route>
    </Routes>
  );
};

export default AppRouter;
