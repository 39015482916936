import React, { useEffect, useRef, useState } from 'react';
import ReactGA from 'react-ga4';
import { HttpHandler } from 'shared/httpHandler';
import { useNavigate } from 'react-router-dom';
import '../../css/milestone10.css';
import { message } from 'antd';
import dayjs from 'dayjs';
import { CryptoHandler } from '../../shared/cryptoHandler';
import html2canvas from 'html2canvas';
import { QRCodeSVG } from 'qrcode.react';

const MemberResultStst = () => {
  const navigate = useNavigate();
  const httpHandler = new HttpHandler();
  const cryptoHandler = new CryptoHandler();

  const [isLongPress, setIsLongPress] = useState(false);
  const [timer, setTimer] = useState(null);
  const PrimaryEnergytotal = parseInt(
    JSON.parse(cryptoHandler.decrypt(localStorage.getItem('UserData'), 2))
      .OrderNum,
    10
  );
  const HybridElectrictotal = parseInt(
    JSON.parse(cryptoHandler.decrypt(localStorage.getItem('UserData'), 2))
      .ElectricMotorCount,
    10
  );
  const cartotal = PrimaryEnergytotal - HybridElectrictotal;
  const carmile = parseInt(
    JSON.parse(cryptoHandler.decrypt(localStorage.getItem('UserData'), 2))
      .CarRentMile,
    10
  );
  const motormile = parseInt(
    JSON.parse(cryptoHandler.decrypt(localStorage.getItem('UserData'), 2))
      .MotorRentMile,
    10
  );
  const miletotal = carmile + motormile;

  let prc = JSON.parse(
    cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
  ).OrderPercent;
  if (prc !== 'X' && prc === '0%') {
    prc = '1%';
  }

  const handleDownload = () => {
    downloadImage();
  };

  const downloadImage = async () => {
    // 這裡指定你想截取的區域
    const element = document.getElementById('milestone10-1');

    // 先顯示，不然無法擷取
    element.style.display = 'block';

    // 截圖
    const canvas = await html2canvas(element, {
      backgroundColor: null, // 如果你的背景是透明或非白色的，設置背景顏色為 null
      useCORS: true, // 如果有跨域資源，例如外部圖片，啟用 CORS
    });
    const image = canvas.toDataURL('image/png');

    // // 建立一個連結並下載圖片
    const link = document.createElement('a');
    link.href = image;
    link.download = 'screenshot.png';
    link.click();

    // 擷取完需要隱藏不然會跑版
    element.style.display = 'none';

    // 打save api
    SaveImage();
  };

  const SaveImage = () => {
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const currentTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
    localStorage.setItem('EventImage', '1');
    localStorage.setItem('ImageSendTime', currentTime);

    // 加密
    const postData = {
      MEMRFNBR: cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2),
      TotalMile: miletotal,
      RentCount: JSON.parse(
        cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
      ).OrderNum,
      EventMessage: localStorage.getItem('EventMessage') || '',
      IsComplete: '1',
      CompleteTime: localStorage.getItem('MessageSendTime') || '',
      IsDownload: '1',
      DownloadTime: currentTime,
      IsCopy: localStorage.getItem('EventLink') || '0',
      CopyTime: localStorage.getItem('LinkSendTime') || '',
      Inviter: localStorage.getItem('Inviter'),
      NiceCatch: access_token,
    };

    const encrypt = cryptoHandler.encrypt(JSON.stringify(postData), 2);
    const request = {
      Request: encrypt,
    };
    const request_json = JSON.stringify(request);
    const headers = {
      'Content-Type': 'application/json',
    };
    httpHandler
      .post('/api/EventSpace/Save', request_json, { headers })
      .then((response) => {
        const info = JSON.parse(cryptoHandler.decrypt(response.Response, 2));
      });
  };

  // 複製專屬連結
  const handleCopyLink = () => {
    const linkToCopy = localStorage.getItem('InvitLink'); // 替換為您的專屬連結
    navigator.clipboard.writeText(linkToCopy).then(
      message
        .success({
          content: '已複製連結',
          style: {
            fontStyle: 'normal',
          },
        })
        .then(() => {
          // 打save api
          SaveLink();
        })
    );
  };

  const SaveLink = () => {
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const currentTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
    localStorage.setItem('EventLink', '1');
    localStorage.setItem('LinkSendTime', currentTime);

    // 加密
    const postData = {
      MEMRFNBR: cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2),
      TotalMile: miletotal,
      RentCount: JSON.parse(
        cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
      ).OrderNum,
      EventMessage: localStorage.getItem('EventMessage') || '',
      IsComplete: '1',
      CompleteTime: localStorage.getItem('MessageSendTime') || '',
      IsDownload: localStorage.getItem('EventImage') || '0',
      DownloadTime: localStorage.getItem('ImageSendTime') || '',
      IsCopy: '1',
      CopyTime: localStorage.getItem('LinkSendTime'),
      Inviter: localStorage.getItem('Inviter'),
      NiceCatch: access_token,
    };

    const encrypt = cryptoHandler.encrypt(JSON.stringify(postData), 2);
    const request = {
      Request: encrypt,
    };
    const request_json = JSON.stringify(request);
    const headers = {
      'Content-Type': 'application/json',
    };
    httpHandler
      .post('/api/EventSpace/Save', request_json, { headers })
      .then((response) => {
        const info = JSON.parse(cryptoHandler.decrypt(response.Response, 2));
      });
  };

  const handleLeftButtonClick = () => {
    navigate('/milestone9');
    window.scrollTo(0, 0); // 滾動到頂部
  };

  const handleRightButtonClick = async () => {
    // 取得排行榜資料
    await Rank();

    navigate('/ranklist');
    window.scrollTo(0, 0); // 滾動到頂部
  };

  useEffect(() => {
    // 在組件掛載時設置 body 的 clas
    document.body.className = 'milestone10';

    // document.body.style.position = 'flex';
    // GA4追蹤頁面瀏覽
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });

    // 在組件卸載時清除 body 的 class
    return () => {
      document.body.className = '';
    };
  }, []);

  // 調整日期格式
  const formatDate = (dateString) => {
    if (dateString !== '') {
      const date = new Date(dateString); // 將字串轉為日期物件
      const year = date.getFullYear();
      const month = date.getMonth() + 1; // 月份從 0 開始計數，所以要加 1
      const day = date.getDate();
      return `${year}/${month}/${day}`; // 返回格式化的日期字串
    } else {
      return '好多年以前';
    }
  };

  // 動態調整專屬稱號圖片
  const getImageSrc = () => {
    const percent = JSON.parse(
      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
    ).OrderPercent;
    const value = parseFloat(percent.replace('%', ''));
    if (value >= 80) return './images/milestone10_box1_lv5.png';
    if (value >= 60) return './images/milestone10_box1_lv4.png';
    if (value >= 40) return './images/milestone10_box1_lv3.png';
    if (value >= 20) return './images/milestone10_box1_lv2.png';
    return './images/milestone10_box1_lv1.png';
  };

  // 調整數字格式
  const formatNumberString = (numberString) => {
    const number = parseInt(numberString, 10); // 將字串轉換為數字
    return number.toLocaleString(); // 格式化為每三位加逗號的格式
  };

  // 取得排行榜資料
  const Rank = async () => {
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const headers = {
      'Content-Type': 'application/json',
    };

    // 取得資料
    await httpHandler
      .get(`/api/EventSpace/Rank?NiceCatch=${access_token}`, { headers })
      .then((response) => {
        const data = JSON.parse(
          cryptoHandler.decrypt(response.Response, 2)
        ).Data;
        const new_rank = cryptoHandler.encrypt(JSON.stringify(data), 2);
        localStorage.setItem('RankData', new_rank);
        PageRecord();
      });
  };

  // 記錄使用者頁面
  const PageRecord = () => {
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const postData = {
      MEMRFNBR: cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2),
      Page: '11',
      NiceCatch: access_token,
    };
    const encrypt = cryptoHandler.encrypt(JSON.stringify(postData), 2);
    const request = {
      Request: encrypt,
    };
    const request_json = JSON.stringify(request);
    const headers = {
      'Content-Type': 'application/json',
    };
    httpHandler.post(
      '/api/EventSpace/PageRecord',
      request_json,
      { headers },
      2
    );
  };

  const handleQrcode = () => {
    const link = localStorage.getItem('InvitLink');
    window.location.href = link;
  };

  return (
    <>
      <div
        className="milestone10"
        id="milestone10-1"
        style={{ display: 'none' }}
      >
        <div className="box-content">
          <br />
          <div className="title-pc">
            <img src="/images/download_bg.png" alt="Main Word PC" />
          </div>
          <div className="title-mobile">
            <img src="/images/download_bg.png" alt="Main Word Mobile" />
          </div>
          <br />
          <div className="box-content1">
            <div className="box-1">
              <img src={getImageSrc()} alt="Level" />
            </div>
            <div className="word-white">
              <p className="word-mile10-purple">
                太空人名稱 :&nbsp;
                {
                  JSON.parse(
                    cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                  ).MEMCNAME
                }
              </p>
              <p>
                登入iRent 時間 :&nbsp;
                {formatDate(
                  JSON.parse(
                    cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                  ).AuditPassDate
                )}
              </p>
              <p>
                想對未來說 :{' '}
                <span className="word-highlight nowrap">
                  “{localStorage.getItem('EventMessage')}”
                </span>
              </p>
            </div>
            <div className="box-2">
              <img src="./images/milestone10_box2.png" alt="" />
              <div className="box-2-text word-white">
                <span className="mileWhite">
                  {formatNumberString(miletotal)}
                </span>
                <span>公里</span>
              </div>
            </div>
            <div className="box-3">
              <img src="./images/milestone10_box3.png" alt="" />
              <div className="box-text word-white">
                <span
                  className={
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).OrderPercent === 'X'
                      ? 'norecord'
                      : 'mileWhite'
                  }
                >
                  {JSON.parse(
                    cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                  ).OrderPercent === 'X'
                    ? '2024暫無記錄快來體驗iRent'
                    : `前 ${prc} 使用者`}
                </span>
              </div>
            </div>
            <div className="box-45">
              <img src="./images/milestone10_box4.png" alt="" />
              <div className="box-text word-white">
                <span className="mileWhite">
                  {formatNumberString(cartotal)}
                  &nbsp;次
                </span>
              </div>
            </div>
            <div className="box-45">
              <img src="./images/milestone10_box5.png" alt="" />
              <div className="box-text word-white">
                <span className="mileWhite">
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).ElectricMotorCount
                  )}
                  &nbsp;次
                </span>
              </div>
            </div>
            <div className="box-6">
              <img src="./images/milestone10_box6.png" alt="" />
              <div className="box-text word-white">
                <span className="mileWhite">
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).OrderLocationCount
                  )}
                </span>
                <span>&nbsp;個</span>
              </div>
            </div>
          </div>
          <div className="box-content2">
            <div className="hashtag"># iRent 10 週年</div>
            <div className="hashtag"># iRent共享汽機車</div>
            <div className="hashtag"># iRent宇你同行</div>
          </div>
        </div>
      </div>
      <div className="milestone10" id="milestone10-2">
        <a id="milestone1ArrowLeft" onClick={() => handleLeftButtonClick()}>
          <div className="arrowLeft"></div>
        </a>
        <a
          id="milestone10_1ArrowRight"
          onClick={() => handleRightButtonClick()}
        >
          <div className="arrowRight"></div>
        </a>
        <div>
          <br />
        </div>
        <div className="logo">
          <img src="./images/logo.png" alt="" />
        </div>
        <div className="box1">
          <div className="number">
            <img src="./images/number_10.png" alt="" />
          </div>
          <div className="text">
            <p>
              iRent 10
              週年感謝有你的一路相伴，我們才能完成這趟不可思議的冒險，最後我們帶著滿滿豐收喜悅回家，這是屬於你的獨特回憶，一起把這份快樂分享給朋友吧
              !
            </p>
          </div>
        </div>
        <div className="box-content">
          <div className="box-content1">
            <div className="box-1">
              {/* 專屬稱號等級 */}
              {/*等級更換圖片 */}
              <img src={getImageSrc()} alt="Level" />
            </div>
            <div className="word-white">
              <p className="word-mile10-purple">
                太空人名稱 :&nbsp;
                {
                  JSON.parse(
                    cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                  ).MEMCNAME
                }
              </p>
              <p>
                登入iRent 時間 :&nbsp;
                {formatDate(
                  JSON.parse(
                    cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                  ).AuditPassDate
                )}
              </p>
              <p>
                想對未來說 :{' '}
                <span className="word-highlight nowrap">
                  “{localStorage.getItem('EventMessage')}”
                </span>
              </p>
            </div>
            <div className="box-2">
              <img src="./images/milestone10_box2.png" alt="" />
              <div className="box-2-text word-white">
                <span className="mileWhite">
                  {formatNumberString(miletotal)}
                </span>
                <span>公里</span>
              </div>
            </div>
            <div className="box-3">
              <img src="./images/milestone10_box3.png" alt="" />
              <div className="box-text word-white">
                <span
                  className={
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).OrderPercent === 'X'
                      ? 'norecord'
                      : 'mileWhite'
                  }
                >
                  {JSON.parse(
                    cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                  ).OrderPercent === 'X'
                    ? '暫無記錄快來體驗iRent'
                    : `前 ${prc} 使用者`}
                </span>
              </div>
            </div>
            <div className="box-45">
              <img src="./images/milestone10_box4.png" alt="" />
              <div className="box-text word-white">
                <span className="mileWhite">
                  {formatNumberString(cartotal)}
                  &nbsp;次
                </span>
              </div>
            </div>
            <div className="box-45">
              <img src="./images/milestone10_box5.png" alt="" />
              <div className="box-text word-white">
                <span className="mileWhite">
                  {formatNumberString(
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).ElectricMotorCount
                  )}
                  &nbsp;次
                </span>
              </div>
            </div>
            <div className="box-6">
              <img src="./images/milestone10_box6.png" alt="" />
              <div className="box-text word-white">
                <span className="mileWhite">
                  {
                    JSON.parse(
                      cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
                    ).OrderLocationCount
                  }
                </span>
                <span>&nbsp;個</span>
              </div>
            </div>
          </div>
          <div className="box-content2">
            <div className="hashtag"># iRent 10 週年</div>
            <div className="hashtag"># iRent共享汽機車</div>
            <div className="hashtag"># iRent宇你同行</div>
          </div>
          <div className="box-content3">
            <div className="left">
              <div className="text">
                <p>將你的iRent專屬成就分享給朋友，</p>
                <p>邀請大家一起成為太空人，還有機會獲得好禮</p>
              </div>
              <div className="qrcodeMobile">
                <a onClick={handleQrcode}>
                  <QRCodeSVG
                    value={localStorage.getItem('InvitLink')}
                    className="qrcode"
                    size={120}
                    fgColor="#000000"
                  />
                </a>
              </div>
              <div className="buttonPart">
                <div className="right-mobile">
                  <a onClick={handleQrcode}>
                    <QRCodeSVG
                      value={localStorage.getItem('InvitLink')}
                      className="qrcode"
                      size={120}
                      fgColor="#000000"
                    />
                  </a>
                </div>
                <button
                  className="btn"
                  id="btnDownload"
                  onClick={handleDownload}
                >
                  <span className="width100">點擊下載圖片</span>
                  <span className="width15" style={{ margin: '0 5px' }}>
                    <img src="./images/download.gif" alt="" />
                  </span>
                </button>
                <button
                  className="btn"
                  id="btnCopyLink"
                  onClick={handleCopyLink}
                >
                  <span className="width100">複製專屬連結</span>
                  <span className="width15" style={{ margin: '0 5px' }}>
                    <img src="./images/link.gif" alt="" />
                  </span>
                </button>
              </div>
            </div>
            <div className="right">
              {/* <img src="./images/milestone10_QRcode.png" alt="" /> */}
              <a onClick={handleQrcode}>
                <QRCodeSVG
                  value={localStorage.getItem('InvitLink')}
                  className="qrcode"
                  size={120}
                  fgColor="#000000"
                />
              </a>
            </div>
          </div>
          <div className="circle-container">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle full"></div>
            <div className="circle"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MemberResultStst;
