import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { HttpHandler } from 'shared/httpHandler';
import { CryptoHandler } from '../../../shared/cryptoHandler';
import { message } from 'antd';

const MileStone9 = () => {
  const httpHandler = new HttpHandler();
  const cryptoHandler = new CryptoHandler();
  const navigate = useNavigate();
  const [inputMessage, setInputMessage] = useState(''); // 用於儲存輸入文字
  const protocol = window.location.protocol;
  const host = window.location.hostname;
  const port = window.location.port;
  const bu = `${protocol}//${host}${port ? `:${port}` : ''}`;
  const invitlink =
    bu +
    '/Index?MEMRFNBR=' +
    cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2);
  const link = invitlink.trim();
  localStorage.setItem('InvitLink', link);

  const carmile = parseInt(
    JSON.parse(cryptoHandler.decrypt(localStorage.getItem('UserData'), 2))
      .CarRentMile,
    10
  );
  const motormile = parseInt(
    JSON.parse(cryptoHandler.decrypt(localStorage.getItem('UserData'), 2))
      .MotorRentMile,
    10
  );
  const miletotal = carmile + motormile;

  useEffect(() => {
    if (localStorage.getItem('EventMessage')) {
      setInputMessage(localStorage.getItem('EventMessage'));
    }
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const handleLeftButtonClick = () => {
    navigate('/milestone8');
  };

  const handleRightButtonClick = () => {
    if (localStorage.getItem('EventMessage') === null) {
      message.warning({
        content: '請寫下祝福並發送火箭',
        style: {
          fontStyle: 'normal',
        },
      });

      return;
    }
    PageRecord();
    navigate('/member_result_stat');
  };

  const PageRecord = () => {
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const postData = {
      MEMRFNBR: cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2),
      Page: '10',
      NiceCatch: access_token,
    };
    const encrypt = cryptoHandler.encrypt(JSON.stringify(postData), 2);
    const request = {
      Request: encrypt,
    };
    const request_json = JSON.stringify(request);
    const headers = {
      'Content-Type': 'application/json',
    };
    httpHandler.post(
      '/api/EventSpace/PageRecord',
      request_json,
      { headers },
      2
    );
  };

  // 發射火箭
  const confirmSubmit = () => {
    if (inputMessage.length === 0) {
      message.warning({
        content: '請輸入留言',
        style: {
          fontStyle: 'normal',
        },
      });

      return;
    }

    if (/[^a-zA-Z0-9\u4e00-\u9fa5]/.test(inputMessage)) {
      message.warning({
        content: '留言內不可使用標點符號',
        style: {
          fontStyle: 'normal',
        },
      });

      return;
    }

    if (inputMessage.length > 8) {
      message.warning({
        content: '留言請限制在8個字之內',
        style: {
          fontStyle: 'normal',
        },
      });

      return;
    }
    const rocket = document.getElementById('rocket');
    const smoke = document.getElementById('smoke');
    const astronaut = document.getElementById('astronaut');
    const inputPlace = document.getElementById('inputPlace');
    const submitButton = document.getElementById('submitButton');

    astronaut.style.transition = 'opacity 1s';
    inputPlace.style.transition = 'opacity 1s';
    submitButton.style.transition = 'opacity 1s';

    astronaut.style.opacity = 0;
    inputPlace.style.opacity = 0;
    submitButton.style.opacity = 0;

    setTimeout(() => {
      rocket.classList.add('shake');
      setTimeout(() => {
        rocket.classList.remove('shake');
        rocket.classList.add('launch');

        smoke.style.opacity = 1;
        smoke.classList.add('grow');

        setTimeout(() => {
          navigate('/member_result_stat');
        }, 2000);
      }, 1000);
    }, 1000);

    SaveMessage();
  };

  // 打save api
  const SaveMessage = () => {
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const currentTime = dayjs().format('YYYY-MM-DD HH:mm:ss');
    localStorage.setItem('EventMessage', inputMessage);
    localStorage.setItem('MessageSendTime', currentTime);
    const postData = {
      MEMRFNBR: cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2),
      TotalMile: miletotal,
      RentCount: JSON.parse(
        cryptoHandler.decrypt(localStorage.getItem('UserData'), 2)
      ).OrderNum,
      EventMessage: inputMessage,
      IsComplete: '1',
      CompleteTime: currentTime,
      IsDownload: localStorage.getItem('EventImage') || '0',
      DownloadTime: localStorage.getItem('ImageSendTime') || '',
      IsCopy: localStorage.getItem('EventLink') || '0',
      CopyTime: localStorage.getItem('LinkSendTime') || '',
      Inviter: localStorage.getItem('Inviter'),
      NiceCatch: access_token,
    };

    const encrypt = cryptoHandler.encrypt(JSON.stringify(postData), 2);
    const request = {
      Request: encrypt,
    };
    const request_json = JSON.stringify(request);
    const headers = {
      'Content-Type': 'application/json',
    };
    httpHandler
      .post('/api/EventSpace/Save', request_json, { headers })
      .then((response) => {
        // 解密
        const info = JSON.parse(cryptoHandler.decrypt(response.Response, 2));
      });
  };

  const handleMessageChange = (event) => {
    setInputMessage(event.target.value);
  };

  return (
    <div className="milestone-main">
      <div className="milestone9">
        <div className="logo">
          <img src="/images/logo.png" alt="Logo" />
        </div>
        <div className="bottom-smoke" id="smoke">
          <img src="./images/milestone9_rocketsmoke_pc.png" alt="" />
        </div>
        <a id="milestone9ArrowLeft" onClick={() => handleLeftButtonClick()}>
          <div className="arrowLeft"></div>
        </a>
        <a id="milestone9ArrowRight" onClick={() => handleRightButtonClick()}>
          <div className="arrowRight"></div>
        </a>
        <div className="box1">
          <div className="number">
            <img src="/images/number_9.png" alt="" />
          </div>
          <div className="text">
            <p>很榮幸有你一起冒險共度2024年的美好旅遊時光，</p>
            <p>
              你是我們最驕傲的夥伴，趁著歲末年終你有沒有想對未來的自己說的話呢 ?
            </p>
            <p>* 溫馨提醒 : 您寫下的祝福話語將有機會被製作成限量實體贈品哦 !</p>
          </div>
        </div>
        <div className="bottom">
          <div className="rocket">
            <img
              src="/images/milestone9_rocket.png"
              alt=""
              id="rocket"
              className="rocket2"
            />
            <div className="astronaut" id="astronaut">
              <img src="/images/milestone9_astronut.png" alt="" />
            </div>
          </div>
          <div className="input-place" id="inputPlace">
            <img src="/images/milestone9_input.png" alt="" />
            <input
              id="input_message"
              type="text"
              className="text-input"
              placeholder="請輸入一段文字"
              value={inputMessage}
              onChange={handleMessageChange}
            />
            {/* 字數顯示 */}
            <span
              style={{
                position: 'absolute',
                right: '60px', // 將字數顯示定位到輸入框內的末尾
                top: '45%',
                transform: 'translateY(-50%)', // 垂直居中
                fontSize: '12px',
                color: inputMessage.length > 8 ? 'red' : 'green', // 超過字數時變紅
                pointerEvents: 'none', // 防止干擾輸入
              }}
            >
              {inputMessage.length}/8
            </span>
          </div>
          <button
            className="btn"
            onClick={() => confirmSubmit()}
            id="submitButton"
          >
            <span className="width85">確定送出</span>
            <img
              src="/images/white_arrow_right.gif"
              alt=""
              className="width20"
              style={{ margin: '0 5px' }}
            />
          </button>
        </div>
        <div className="circle-container">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle full"></div>
          <div className="circle"></div>
          <div className="circle"></div>
        </div>
      </div>
    </div>
  );
};

export default MileStone9;
