import React from 'react';
import _axios from 'axios';
import moment from 'moment-timezone';
import { CryptoHandler } from './cryptoHandler';

class HttpHandler {
  constructor(
    checkToken = true,
    baseURL = process.env.REACT_APP_BASE_URL || 'http://localhost:8000'
  ) {
    this.checkToken = checkToken;
    this.baseURL = baseURL;
  }
  axios = (baseURL) => {
    const instance = _axios.create({
      baseURL: this.baseURL,
      timeout: 5000000,
    });
    return instance;
  };

  navigateTo(url) {
    window.navigate(url);
  }

  // post method
  async post(url, data, config = {}, use_test_url = 1) {
    // token 過期
    if (config.headers === null) {
      window.logout('登入逾時', '請重新登入', 'fail');
    }

    // use_test_url == 2 => 改成使用新的測試網址
    if (use_test_url == 2) {
      this.baseURL = process.env.REACT_APP_TEST_URL;
    } else {
      this.baseURL = process.env.REACT_APP_BASE_URL;
    }
    // config.data = { unused: 0 };
    return this.axios()
      .post(url, data, config)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          return { success: false, message: '連線錯誤.' };
        }
        if (error.response.status === 401) {
          this.navigateTo('error401');
        } else if (error.response.status === 422) {
          return { success: false, message: error.response.data.detail[0].msg };
        } else {
          return { success: false, message: 'http handler error' };
        }
      });
  }

  // put method
  async put(url, data, contentType = 'application/json') {
    // token 過期
    if (headers === null) {
      window.logout('登入逾時', '請重新登入', 'fail');
    }

    return this.axios()
      .put(url, data, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          return { success: false, message: '連線錯誤.' };
        }
        if (error.response.status === 401) {
          this.navigateTo('error401');
        } else if (error.response.status === 422) {
          return { success: false, message: error.response.data.detail[0].msg };
        } else {
          return { success: false, message: 'http handler error' };
        }
      });
  }

  // get method
  async get(url, config = {}, use_test_url = 1) {
    // token 過期
    if (config.header === null) {
      window.logout('登入逾時', '請重新登入', 'fail');
    }
    // 加入這行可以強制加入Content-Type
    config.data = { unused: 0 };

    // use_test_url == 2 => 改成使用新的測試網址
    if (use_test_url == 2) {
      this.baseURL = process.env.REACT_APP_TEST_URL;
    } else {
      this.baseURL = process.env.REACT_APP_BASE_URL;
    }

    return this.axios()
      .get(url, config)
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          return { success: false, message: '連線錯誤.' };
        }
        if (error.response.status === 401) {
          this.navigateTo('error401');
        } else if (error.response.status === 422) {
          return { success: false, message: '後端API資料驗證失敗, 請確認.' };
        } else {
          return { success: false, message: 'http handler error' };
        }
      });
  }

  // delete method
  async delete(url) {
    // token 過期
    if (headers === null) {
      window.logout('登入逾時', '請重新登入', 'fail');
    }

    return this.axios()
      .delete(url, { headers: headers })
      .then((response) => {
        if (response.status === 200) {
          return response.data;
        } else {
          return { success: false, message: 'http handler error' };
        }
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          return { success: false, message: '連線錯誤.' };
        }
        if (error.response.status === 401) {
          this.navigateTo('error401');
        } else if (error.response.status === 422) {
          return { success: false, message: '後端API資料驗證失敗, 請確認.' };
        } else {
          return { success: false, message: 'http handler error' };
        }
      });
  }
}

// auth helper
class AuthValid {
  // save login info
  static storeAuth(access, refresh) {
    localStorage.setItem('irentAccessToken', access);
    localStorage.setItem('irentRefreshToken', refresh);

    const payLoad = AuthValid.parseJwt(access);

    // 登入時間
    localStorage.setItem(
      'loginTime',
      moment
        .utc(payLoad.token_time)
        .tz(moment.tz.guess())
        .format('YYYY/MM/DD HH:mm:ss')
    );

    // AccessToken 過期分鐘數
    localStorage.setItem(
      'irentAccessTokenExpireMinutes',
      payLoad.token_expire_minutes
    );

    // RefreshToken 過期分鐘數
    localStorage.setItem(
      'irentRefreshTokenExpireMinutes',
      payLoad.re_token_expire_minutes
    );

    // AccessToken 過期時間
    localStorage.setItem(
      'irentAccessTokenExpireTime',
      moment(new Date())
        .add(parseInt(payLoad.token_expire_minutes) - 5, 'minutes')
        .format('YYYY/MM/DD HH:mm:ss')
    );

    // RefreshToken 過期時間
    localStorage.setItem(
      'irentRefreshTokenExpireTime',
      moment(new Date())
        .add(parseInt(payLoad.re_token_expire_minutes) - 5, 'minutes')
        .format('YYYY/MM/DD HH:mm:ss')
    );
  }

  static parseJwt(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  }
}

export { HttpHandler, AuthValid };
