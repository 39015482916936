import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';

const MileStone8Not = () => {
  const navigate = useNavigate();
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const handleLeftButtonClick = () => {
    navigate('/milestone7notmember');
  };
  const handleRightButtonClick = () => {
    navigate('/not_member_result');
  };
  return (
    <div className="milestone-main">
      <div className="milestone8">
        <div className="logo">
          <img src="/images/logo.png" alt="Logo" />
        </div>
        <a id="milestone8ArrowLeft" onClick={() => handleLeftButtonClick()}>
          <div className="arrowLeft"></div>
        </a>
        <a id="milestone8ArrowRight" onClick={() => handleRightButtonClick()}>
          <div className="arrowRight"></div>
        </a>
        <div className="box1">
          <div className="number">
            <img src="/images/number_8.png" alt="" />
          </div>
          <div className="text word-mileorange">
            <p>
              聰明的太空人怎麼能錯過iRent PRO，路邊租還的「PRO可還車停車場」
            </p>
            <p>及同站租還的「PRO預約清潔服務」兩大加值服務，</p>
            <p>一起來當銀河最PRO的太空人!</p>
          </div>
        </div>
        <div className="circle-container">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle full"></div>
          <div className="circle"></div>
        </div>
      </div>
    </div>
  );
};

export default MileStone8Not;
