import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { json, useNavigate } from 'react-router-dom';
import { HttpHandler } from 'shared/httpHandler';
import { Button, Modal, Typography, message } from 'antd';
import { CryptoHandler } from '../../shared/cryptoHandler';
import { PWencrypt } from '../../shared/PWencrypt';
import '../../css/milestone10.css';

const Login = () => {
  const httpHandler = new HttpHandler();
  const cryptoHandler = new CryptoHandler();
  const pwencrypt = new PWencrypt();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    userAccount: '',
    userPassword: '',
  });
  const [isModalVisible, setIsModalVisible] = useState(false);
  const textmessage = `本活動不論是否為iRent會員皆能參與體驗，惟部分活動僅限會員參加。非iRent會員者亦須先下載iRent App，註冊帳號、上傳汽車或機車駕照並申請審核通過成為iRent會員，方可領取iRent折抵券且使用iRent共享汽機車服務。`;
  const msg1 = `發放時間：活動獲得之折抵券將於次日內自動匯入iRent App「優惠管理」。`;
  const msg2 = `於活動期間完成任務次數最多的會員前20名可獲得限量贈品。 (如完成次數相同則以完成時間優先決定名次)`;
  const msg3 = `《宇宙漂流信》實體毛巾係指-會員於活動網頁”宇宙漂流信”單元中留下之祝福文字，印製為實體毛巾圖案。`;
  const msg4 = `本活動累積里程採計時間 : 2024/1/1~2024/11/10，並以iRent系統端留存數據為主要依據。`;
  const msg5 = `活動獎品 : iRent 汽車60分鐘折抵券1張+機車前6分鐘折抵券2張 (共計抽出1,000組)`;
  const msg6 = `折抵券不適用農曆春節期間，春節期間依iRent App公告為主。`;
  const msg7 = `兌換iRent汽車時數前須先下載iRent App，並申請審核通過成為iRent會員，方可兌換時數及使用iRent共享汽機車服務。（完成註冊程序需包含：建立帳號、上傳汽車或機車駕照並審核通過）`;
  const msg8 = `iRent汽車時數須於規範期限前至iRent App內兌換序號，並於「兌換後使用期限內」使用完畢；未於時間內使用完畢之時數，不得要求補發剩餘免費時數。`;
  const msg9 = `iRent汽車時數適用於TOYOTA PRIUS C、VIOS、YARIS、YARIS CROSS、Swift、Fit、TownAceVan、SIENTA、ALTIS、COROLLA CROSS之車款，若有調整將依iRent官方粉絲團公告為準。`;
  const msg10 = `兌換辦法：請下載iRent App，完成註冊成為iRent會員，至App>點選左上角【三】>【優惠管理】>右上方【折抵券】>【折抵券兌換】>【輸入兌換碼】。`;

  const Check_Chinese_Symbols = (value) => {
    return /[^\w\s]/.test(value) || /[\u4E00-\u9FFF]/.test(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      Check_Chinese_Symbols(formData.userAccount) ||
      Check_Chinese_Symbols(formData.userPassword)
    ) {
      message.warning({
        content: '請勿於帳號或密碼欄位輸入中文及標點符號',
        style: {
          fontStyle: 'normal',
        },
      });

      return;
    }

    // 加密
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const encrypt_pw = '0x' + pwencrypt.encrypt(formData.userPassword);

    // 帳密登入
    const postData = {
      Type: '2',
      UserID: formData.userAccount.toUpperCase(),
      UserPW: encrypt_pw,
      NiceCatch: access_token,
    };

    // 手機登入
    // const postData = {
    //   Type: '1',
    //   Mobile: '0960033775',
    // };
    const encrypt = cryptoHandler.encrypt(JSON.stringify(postData), 2);
    const request = {
      Request: encrypt,
    };
    const request_json = JSON.stringify(request);
    const headers = {
      'Content-Type': 'application/json',
    };

    // 登入
    httpHandler
      .post('/api/EventSpace/Login', request_json, { headers })
      .then(async (response) => {
        // 解密
        const logininfo = JSON.parse(
          cryptoHandler.decrypt(response.Response, 2)
        );
        if (logininfo.Result === 1) {
          const new_mem = cryptoHandler.encrypt(logininfo.Data.MEMRFNBR, 2);
          const new_extok = cryptoHandler.encrypt(logininfo.Data.EventToken, 2);
          const new_extime = cryptoHandler.encrypt(
            logininfo.Data.ExpireTime,
            2
          );

          localStorage.setItem('MEMRFNBR', new_mem);
          localStorage.setItem('EventToken', new_extok);
          localStorage.setItem('ExpireTime', new_extime);

          // 取得個別會員資料
          await GetMemberInfo();

          navigate('/milestone1');
        } else {
          alert('帳號或密碼錯誤');
        }
      });
  };

  // 取得個別會員資料
  const GetMemberInfo = async () => {
    // 加密
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const postData = {
      MEMRFNBR: cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2),
      NiceCatch: access_token,
    };
    const encrypt = cryptoHandler.encrypt(JSON.stringify(postData), 2);
    const request = {
      Request: encrypt,
    };
    const request_json = JSON.stringify(request);
    const headers = {
      'Content-Type': 'application/json',
    };
    await httpHandler
      .post('/api/EventSpace/GetMemberData', request_json, { headers })
      .then((response) => {
        // 解密
        const info = JSON.parse(cryptoHandler.decrypt(response.Response, 2));
        const decry_info = cryptoHandler.encrypt(JSON.stringify(info.Data), 2);
        localStorage.setItem('UserData', decry_info);
        PageRecord();
      });
  };

  // 紀錄當前頁面 => 會員頁面1
  const PageRecord = () => {
    const access_token = cryptoHandler.decrypt(
      localStorage.getItem('AccessToken'),
      2
    );
    const postData = {
      MEMRFNBR: cryptoHandler.decrypt(localStorage.getItem('MEMRFNBR'), 2),
      Page: '1',
      NiceCatch: access_token,
    };
    const encrypt = cryptoHandler.encrypt(JSON.stringify(postData), 2);
    const request = {
      Request: encrypt,
    };
    const request_json = JSON.stringify(request);
    const headers = {
      'Content-Type': 'application/json',
    };
    httpHandler.post(
      '/api/EventSpace/PageRecord',
      request_json,
      { headers },
      2
    );
  };

  // 取得帳密字串
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // 點擊"我還不是會員" 返回logininfo
  const handleGoLoginInfoButton = () => {
    navigate('/logininfo');
  };

  // GA4
  useEffect(() => {
    // 追蹤頁面瀏覽
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  return (
    <div className="login-main">
      <div className="login-bg">
        <div className="logo">
          <img src="./images/logo.png" alt="" />
        </div>
        <div className="container content">
          <div className="title">
            <img src="./images/Mainword_W.png" alt="" />
          </div>
          <div className="input-box">
            <form id="loginForm" action="" onSubmit={handleSubmit}>
              <div className="topic">iRent會員登入</div>
              <div className="input-group">
                <label htmlFor="username">帳 號：</label>
                <input
                  type="text"
                  id="userAccount"
                  name="userAccount"
                  value={formData.userAccount}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="input-hint">(不分大小寫)</div>
              <div className="input-group">
                <label htmlFor="password">密 碼：</label>
                <input
                  type="password"
                  id="userPassword"
                  name="userPassword"
                  value={formData.userPassword}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="box-hint">
                <p>
                  如登入活動則代表已同意
                  <span
                    className="focus"
                    id="activityAttention"
                    onClick={() => setIsModalVisible(true)}
                  >
                    活動注意事項
                  </span>
                </p>
              </div>
              <div className="actions">
                <button
                  type="button"
                  className="btn-secondary"
                  id="notMemberBtn"
                >
                  <img
                    src="./images/red_arrow_left.gif"
                    alt=""
                    className="width15"
                    style={{ margin: '0 5px' }}
                  />
                  <span
                    className="width85"
                    onClick={() => handleGoLoginInfoButton()}
                  >
                    我還不是會員
                  </span>
                </button>
                <button type="submit" className="btn" id="loginBtn">
                  <span className="width85">出發冒險</span>
                  <img
                    src="./images/white_arrow_right.gif"
                    alt=""
                    className="width15"
                    style={{ margin: '0 5px' }}
                  />
                </button>
              </div>
            </form>
            <div className=""></div>
          </div>
        </div>
        <Modal
          title="活動注意事項"
          open={isModalVisible}
          onCancel={() => setIsModalVisible(false)}
          footer={
            <Button
              key="close"
              type="primary"
              onClick={() => setIsModalVisible(false)}
            >
              關閉
            </Button>
          }
        >
          <Typography.Paragraph>
            <pre style={{ maxHeight: '400px', overflowY: 'auto' }}>
              {/* {JSON.parse(localStorage.getItem('AllMemberData')).EventNoting} */}
              <div className="box-content4">
                <div className="content" style={{ fontSize: 12 }}>
                  《注意事項》
                  <br />
                  <span className="message">
                    ⚫&nbsp;本活動由和雲行動服務(以下簡稱「主辦單位」)主辦，參與者參加本活動同時，即同意接受本活動「注意事項」、「個人資料告知事項」之規範，如有任何違反之情事，主辦單位得取消其中獎資格，並對於任何破壞本活動之行為行使法律追訴權。
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;具有得獎資格之iRent會員帳號(同身分證字號)、手機號碼需為真實身分，活動應由本人親自參與，不得由他人代為參加，不得為人頭帳號、假帳號，若使用者以人頭帳號或電腦駭客程式等不正當之手段參與活動，經主辦單位發現或經第三人檢舉，則主辦單位有權取消使用者之參與、得獎資格，主辦單位不另行通知，並保留法律追訴權。
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;主辦單位保留修改活動及獎品、審查參與者及中獎者資格等之權利，並保留隨時取消、終止、修改或暫停與調整活動內容、活動辦法、注意事項、獎項及領獎方式等相關細節之權利，無須作事前通知，亦有權對本活動之所有事宜作出補充、解釋或裁決。
                  </span>
                  <span className="message">
                    <br />
                    若因本活動發生訴訟時，以台灣台北地方法院為第一審管轄法院。
                  </span>
                  <br />
                  <br />
                  《活動辦法》
                  <br />
                  <span className="message">
                    {textmessage}
                    <br />
                  </span>
                  <br />
                  《活動一、iRent會員年度回顧體驗》
                  <span className="message">
                    <br />
                    活動期間：即日起~2024/12/31(二)
                    <br />
                  </span>
                  <span className="message">
                    活動資格：不論是否為iRent會員皆能參與體驗，惟僅會員登入後可查看個人專屬回顧紀錄。
                    <br />
                  </span>
                  <span className="message">
                    活動辦法：2024/12/31(二)前登入活動網頁並完成「iRent會員年度回顧」活動，即可獲得iRent共享汽機車折抵券。
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;{msg1}
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;折抵券領取期間：即日起~2024/12/31。
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;折抵券使用效期：2025/1/23
                    <br />
                  </span>
                  <br />
                  《活動二、專屬連結邀請會員體驗抽獎》
                  <span className="message">
                    <br />
                    ⚫&nbsp;活動資格：邀請者、被邀請者皆需為iRent有效會員。
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;活動辦法：於活動期間，會員體驗完成回顧後可查看個人結果頁面，並分享專屬連結邀請其他會員參與，被邀請會員完成活動體驗後，邀請者即可獲得抽獎資格，會員分享連結無次數上限，惟每人僅限獲獎一次。
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;活動獎品 : iRent 運動毛巾 乙份 (共計抽出50組)。
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;獎項發放時間 : 於2025/02/28前寄出。
                    <br />
                  </span>
                  <br />
                  《專屬連結邀請會員體驗-排行榜》
                  <span className="message">
                    <br />
                    ⚫&nbsp;活動資格：邀請者、被邀請者皆需為iRent有效會員。
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;活動時間 : 即日起~2024/12/31(二)
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;活動辦法：活動期間會員完成回顧後查看個人結果頁面並從活動網頁分享專屬連結。
                    <br />
                  </span>
                  <span className="message">
                    1.&nbsp;邀請其他iRent會員一同參與本活動網頁並完成體驗。
                    <br />
                  </span>
                  <span className="message">
                    2.&nbsp;當雙方皆完成活動體驗後即計算為完成一次任務。
                    <br />
                  </span>
                  <span className="message">
                    3.&nbsp;{msg2}
                    <br />
                  </span>
                  <span className="message">
                    4.&nbsp;會員可由活動頁面之排行榜確認目前個人分享次數。
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;活動獎品 :《宇宙漂流信》實體毛巾乙份 (限量20份)
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;獎項發放時間 : 於2025/2/28前寄出。
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;{msg3}
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;本活動嚴禁留下違反善良風俗、人身攻擊、不雅內容及不法之訊息，如有發現將有權刪除任何不妥之內容。
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;如最終獲獎者之活動留言訊息經本公司判斷有不適言論，本公司活動小組擁有不經告知逕自變更文案之權利，
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;禁止統獨、政治、族群、商業廣告或其他任何不法之文字內容。
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;參與者同意所留存或產生之任何參與本活動的資料或記錄，皆以主辦單位之電腦系統與時間紀錄為主。本活動如任何因電腦、網路、電話、技術或其他不可歸責於主辦單位之事由，而使參與者所登錄、填寫或寄出之資料有延遲、遺失、錯誤、無法辨識、毀損或無效之狀況，主辦單位不負任何法律責任，參與者與中獎者亦不得異議。
                    <br />
                  </span>
                  <br />
                  《活動四、2024年度會員累積里程貢獻獎》
                  <br />
                  <span className="message">
                    ⚫&nbsp;活動資格：限iRent會員參與
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;活動時間 : 即日起~2024/12/31(二)
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;活動辦法：
                    <br />
                  </span>
                  <span className="message">
                    1.&nbsp;2024年度(iRent汽車)累積里程最高前40名可獲贈活動贈品
                    <br />
                  </span>
                  <span className="message">
                    2.&nbsp;2024年度(iRent機車)累積里程最高前10名可獲贈活動贈品
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;活動獎品 : iRent 10週年紀念徽章乙份 (限量50份)
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;{msg4}
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;獎項發放時間 : 於2024/12/31前寄出。
                    <br />
                  </span>
                  <br />
                  《活動五、10週年限量加碼活動》
                  <br />
                  <span className="message">
                    ⚫&nbsp;活動資格：僅限iRent會員參與
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;活動時間 : 即日起~12/31(二)
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;活動辦法：
                    <br />
                  </span>
                  <span className="message">
                    1.&nbsp;2024年有租iRent汽車10趟或機車10趟以上之用戶且須完成宇你同行回顧即可參加本活動抽獎(*需汽車滿10趟或機車滿10趟，不可汽機車累加計算)
                    <br />
                  </span>
                  <span className="message">
                    2.&nbsp;本活動採計2024/1/1~2024/11/10期間內租車滿10趟之會員
                    <br />
                  </span>
                  <span className="message">
                    3.&nbsp;單趟租金結算為零元之訂單不列入計算
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;{msg5}
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;兌換期間:2024/12/2~2024/12/31，使用效期至1/23。
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;{msg6}
                    <br />
                  </span>
                  <span className="message">
                    ⚫&nbsp;獎項發放時間 : 於2025/1/3前發出。
                    <br />
                  </span>
                  <br />
                  《個人資料告知事項》
                  <br />
                  <span className="message">
                    參與者同意主辦單位依個人資料保護法規定，得為參與者基於執行本抽獎活動、行銷、消費者、客戶管理與服務、廣告或商業行為管理、調查、統計與研究分析之目的，於台灣地區及網際網路可達區域蒐集、處理及利用參與者之個人資料（包含參與者姓名、手機號碼、聯絡地址、iRent帳號等)。主辦單位將以參與者留存之聯絡方式，使參與者知悉權益事項，並將個人資料提供配合之廠商進行贈獎相關事宜。
                    <br />
                  </span>
                  <br />
                  <span className="message">
                    參與者依照個人資料保護法得隨時請求主辦單位查閱、給予複本、或補正參與者之個人資料，亦得隨時洽主辦單位表達拒絕相對人繼續蒐集、處理、利用或刪除參與者之個人資料；參與者同意由主辦單位持續蒐集、處理、利用參與者所提供之個人資料，截至參與者主動請求主辦單位刪除、停止處理或利用該個人資料之日或主辦單位執行業務所必需之保存期間止，主辦單位始終止其個人資料之蒐集、處理及利用。參與者得自由選擇是否提供相關個人資料，惟若參與者不提供時，參與者瞭解將無法參與本抽獎活動。
                    <br />
                  </span>
                  <br />
                  《新會員禮包活動辦法》
                  <br />
                  <span className="message">
                    1.&nbsp;新會員禮包折抵券含汽車60分鐘折抵券1張、機車前6分鐘折抵券2張，僅限iRent新會員輸入，每人限領一次。
                    <br />
                  </span>
                  <span className="message">
                    2.&nbsp;禮包兌換期間:即日起~2024/12/31，使用效期兌換後60日。
                    <br />
                  </span>
                  <span className="message">
                    3.&nbsp;{msg6}
                    <br />
                  </span>
                  <span className="message">
                    4.&nbsp;{msg10}
                    <br />
                  </span>
                  <span className="message">
                    5.&nbsp;本活動所提供之折抵券，皆遵從iRent折抵券相關規則與辦法，所有經由本活動領取之折抵券，皆須在符合iRent折抵券功能/規則等辦法之下，方得以兌換與使用，用戶不得要求退款、折換現金、遞延使用，亦不得將其轉讓予第三人。
                    <br />
                  </span>
                  <span className="message">
                    6.&nbsp;本活動序號與好友推薦序號、其他iRent新會員活動免費時數序號僅能擇一輸入。iRent會員未審核通過前即可輸入兌換碼，通過審核後方可使用。
                    <br />
                  </span>
                  <br />
                  《折抵券使用規則》
                  <br />
                  <span className="message">
                    1.&nbsp;本活動所提供之折抵券，皆遵從iRent折抵券相關規則與辦法，所有經由本活動領取之折抵券，皆須在符合iRent折抵券功能/規則等辦法之下，方得以兌換與使用，用戶不得要求退款、折換現金、遞延使用，亦不得將其轉讓予第三人。
                    <br />
                  </span>
                  <span className="message">
                    2.&nbsp;每筆訂單限用一張折抵券，無法多張券合併使用。折抵券不得與企業月結訂單併用。每一張券僅能使用一次，若未使用完剩餘時數即失效。折抵券僅能折抵租金，無法折抵里程費、etag、罰款、逾時費用等其他費用，請參與者知悉留意。
                    <br />
                  </span>
                  <span className="message">
                    3.&nbsp;和雲行動服務股份有限公司保留本活動內容調整、取消、終止及最終解釋之權利，如有任何疑問(包含個人資料告知事項)，請透過文字客服(https://reurl.cc/zYbW6V)與主辦單位聯絡。
                    <br />
                  </span>
                </div>
              </div>
            </pre>
          </Typography.Paragraph>
        </Modal>
      </div>
    </div>
  );
};

export default Login;
