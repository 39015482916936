import React, { useEffect } from 'react';
import ReactGA from 'react-ga4';
import { useNavigate } from 'react-router-dom';
import { CryptoHandler } from 'shared/cryptoHandler';

const MileStone4Not = () => {
  const navigate = useNavigate();
  const cryptoHandler = new CryptoHandler();
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.pathname + window.location.search,
    });
  }, []);

  const handleLeftButtonClick = () => {
    navigate('/milestone3notmember');
  };

  const handleRightButtonClick = () => {
    navigate('/milestone5notmember');
  };

  const formatNumberString = (numberString) => {
    const number = parseInt(numberString, 10); // 將字串轉換為數字
    return number.toLocaleString(); // 格式化為每三位加逗號的格式
  };

  return (
    <div className="milestone-main">
      <div className="milestone4">
        <div className="logo">
          <img src="/images/logo.png" alt="Logo" />
        </div>
        <a id="milestone4ArrowLeft" onClick={() => handleLeftButtonClick()}>
          <div className="arrowLeft"></div>
        </a>
        <a id="milestone4ArrowRight" onClick={() => handleRightButtonClick()}>
          <div className="arrowRight"></div>
        </a>
        <div className="box1">
          <div className="number">
            <img src="/images/number_4.png" alt="" />
          </div>
          <div className="text word-mileorange">
            <p>
              你知道嗎? 這些年來會員數已突破&nbsp;
              <span className="word-white nowrap">
                {formatNumberString(
                  JSON.parse(
                    cryptoHandler.decrypt(
                      localStorage.getItem('AllMemberData'),
                      2
                    )
                  ).TotalMemberCount
                )}
              </span>
              &nbsp;人，
            </p>
            <p>從1個人到100,000人，iRent 始終保持初心，創新領航、服務躍進</p>
          </div>
        </div>
        <div className="circle-container">
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle full"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
          <div className="circle"></div>
        </div>
      </div>
    </div>
  );
};

export default MileStone4Not;
